import React from 'react'
import styled from 'styled-components'

const InicioCuestionario = ({initForm}) => {
  return(
    <div className="panel-view-info">
      <div className="shadow pop-up-cuestionario">
        <div className="text-explicativo-panel">
          <Title>PASO 2: A partir de aquí empiezas la sección de orientación académica.</Title>
          Este paso te tomará 25 minutos.
          <br />
          Responderás a una serie de preguntas que sirven para orientarte en tu elección de carrera universitaria. 
          <br />
          <br />
          Estas preguntas no suman en la puntuación final, simplemente sirven para generar tu informe de orientación así que te pedimos ante todo sinceridad a la hora de elegir tus respuestas.
          <br />
          <br />
          ¡Busca un lugar tranquilo, y a por ello!
          <br />
        </div>
        <ActionsButton>
          <div
            className="btn_send_form pointer noselect"
            onClick={initForm}
          >
            Empezar
          </div>
        </ActionsButton>
        <br />
        <Text>Para conseguir el informe con recomendación de universidades de EE.UU., Canadá, Irlanda, y Gran Bretaña deberás completar los 3 pasos</Text>
      </div>
    </div>
  )
}

export default InicioCuestionario

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  line-height: 22px;
`

const ActionsButton = styled.div`
  display: flex;
  justify-content: center;
`

const Text = styled.div`
    text-align: justify;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 25px;
`