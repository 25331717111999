import React, { useLayoutEffect } from 'react'
import { pathFormularioNewColegio } from '../../../utils/constans';
import styled from 'styled-components'
import { sendEmailSchool } from '../../../utils/functions'

const GuardadoCorrectamente = ({ email }) => {

  const refreshPage = () => {
    window.location.reload();
  }

  const handleConfirmationEmail = async () => {
    const response = await sendEmailSchool(email)
    console.log(response)
  }

  useLayoutEffect(() => {
    handleConfirmationEmail()
  }, [])

  return(
    <Container>
      <div className='panel-view-info'>
        <center className='shadow pop-up-cuestionario pr'>

          <div className="btn_cerrar_pop_up pointer" onClick={() => window.location.href=pathFormularioNewColegio}><i className="material-icons">close</i></div>


          <div className='text-explicativo-panel'>
            <Title>
              ¡Enhorabuena!<br/>
              Has completado la inscripción de tu centro educativo en el programa Unidream.
            </Title>
            <Text>
              En unos días nos pondremos en contacto contigo para coordinar el primer paso: la charla de alumnos y padres para contarles las ventajas de participar y para ayudarles en su inscripción.<br/><br/>
              Si te surge cualquier duda contáctanos por correo electrónico o por teléfono y atenderemos todas tus dudas.<br/><br/>
              ¡Gracias por participar y por darle esta oportunidad a tus alumnos!
            </Text>

          </div>
          
        </center>
      </div>
    </Container>
  )
}

export default GuardadoCorrectamente

const Container = styled.div`
  margin-top: 24px;
`

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  line-height: 26px;
`

const Text = styled.div`
  text-align: justify;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: 500;
`


{/* <div className="div_success">
      <div className="center-tag">
        <div className="info_success shadow pr">
          <div>Tu colegio se ha inscrito correctamente.</div>
          <div className="btn_cerrar_pop_up pointer" onClick={() => window.location.href=pathFormularioNewColegio}><i className="material-icons">close</i></div>
          <div className="margin_top_15"><i className="material-icons check_send_formulario">check</i></div>
        </div>
      </div>
    </div> */}