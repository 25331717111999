import React, { useState, memo } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import dotProp from 'dot-prop-immutable';
import ReactHtmlParser from 'react-html-parser';
import { scrollTopAllScreen } from '../../../utils/functions';

import DateCountdown from 'react-date-countdown-timer';
import { pathImagesExamen } from '../../../utils/constans';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumnoExamen } from '../../../redux/actions/alumnosActions';
import { DoneRounded } from '@material-ui/icons';
import RadioButton from '../../Shared/RadioButton';
import _ from 'underscore';
import { db } from '../../../utils/firebase';

import { directionsMates } from '../Data/Matematicas';

const PreguntaMatematicas = ({
  time,
  setPanelExamen,
  currentAlumno,
  localesDateCountdown,
  setCurrentAlumnoProps,
  inscripcion,
  localespluralDateCountdown,
  type,
  actions
}) => {
  const [currentAlumnoState, setCurrentAlumnoState] = useState(currentAlumno);

  const [preguntaSelected, setPreguntaSelected] = useState(0);
  const [finSection, setFinSection] = useState(false);

  const changeRespuesta = (idRespuesta, value) => {
    if (idRespuesta === 'input' && !value.trim()) {
      actions.modifyCurrentAlumnoExamen({
        path: `matematicas.${type}.preguntas.${preguntaSelected}.respuestaSelected`,
        value: null
      });
    } else {
      actions.modifyCurrentAlumnoExamen({
        path: `matematicas.${type}.preguntas.${preguntaSelected}.respuestaSelected`,
        value: { idRespuesta, value }
      });
    }

    //currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[idPregunta].respuestaSelected
    //todo guardar en bbdd
  };

  const nextPregunta = () => {
    if (+preguntaSelected <= 0) {
      setPreguntaSelected(1);
      return false;
    }
    saveData();
    const preguntas = Object.entries(currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas);
    const lastPregunta = +preguntas[preguntas.length - 1][0];
    if (lastPregunta === +preguntaSelected) {
      setFinSection(true);
    } else {
      setPreguntaSelected(+preguntaSelected + 1);
    }
    scrollTopAllScreen();
  };

  const previousPregunta = () => {
    saveData();
    if (preguntaSelected === 1) {
      //no hacer nada
      setPreguntaSelected(0);
    } else {
      setPreguntaSelected(+preguntaSelected - 1);
    }
    scrollTopAllScreen();
  };

  const saveData = () => {
    const respuestaSelectedState = currentAlumnoState.inscripcionAlumno.sat.examen.matematicas[type].preguntas[preguntaSelected].respuestaSelected;
    const respuestaSelected = currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[preguntaSelected].respuestaSelected;
    if (!_.isEqual(respuestaSelectedState, respuestaSelected)) {
      let multiPath = {};
      multiPath[
        `Inscripciones/Alumnos/${inscripcion}/${currentAlumno.id}/sat/examen/matematicas/${type}/preguntas/${preguntaSelected}/respuestaSelected`
      ] = respuestaSelected;
      db.update(multiPath)
        .then(() => {})
        .catch(err => {});
      setCurrentAlumnoState(currentAlumno);
      setCurrentAlumnoProps(currentAlumno);
    }
  };

  const changeStep = idPregunta => {
    if (+preguntaSelected <= 0) {
      setPreguntaSelected(idPregunta);
      return false;
    }
    saveData();
    setPreguntaSelected(idPregunta);
  };

  const pathImage =
    preguntaSelected > 0 ? `${type}-${preguntaSelected}-${currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[preguntaSelected].tipo}` : '';
  const pregunta = preguntaSelected > 0 ? currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[preguntaSelected] : '';

  return (
    <div className='panel-ingles all-screen'>
      <div className='contador contador-fixed'>
        <DateCountdown
          dateTo={time.toString()}
          callback={() => setPanelExamen()}
          mostSignificantFigure='min'
          locales={localesDateCountdown}
          locales_plural={localespluralDateCountdown}
        />
      </div>

      {!finSection && (
        <>
          {preguntaSelected <= 0 && (
            <div className='info-ingles-panel'>
              <div className='title-info'>{ReactHtmlParser(directionsMates[type].text)}</div>
              <div className='directions'>
                <img src={directionsMates[type].notes} />
              </div>
              <div className='directions'>
                <img src={directionsMates[type].references} />
              </div>
              <div className='btns-examen-next'>
                <Button className='back-btn' disabled={false} variant='contained' onClick={() => nextPregunta()}>
                  Next
                </Button>
              </div>
            </div>
          )}

          {preguntaSelected > 0 && (
            <div className={'content-imagen-pregunta'}>
              <div>
                <div className='pregunta-imagen-container'>
                  <img src={`${pathImagesExamen}/Matematicas/${pathImage}/${pathImage}.jpg`} />
                </div>

                {pregunta.foto && (
                  <ul className='lista-respuestas'>
                    {pregunta.respuestas.map((letter, idRespuesta) => (
                      <li>
                        <RadioButton
                          checked={pregunta.respuestaSelected?.idRespuesta === idRespuesta}
                          changeValue={() => changeRespuesta(idRespuesta, letter)}
                        />{' '}
                        <img src={`${pathImagesExamen}/Matematicas/${pathImage}/${letter}.jpg`}></img>
                      </li>
                    ))}
                  </ul>
                )}

                {!pregunta.foto && pregunta.input && (
                  <div className='container-input-mates'>
                    <div>Respuesta:</div>
                    <input
                      value={pregunta.respuestaSelected?.value ?? ''}
                      placeholder='Introduce respuesta'
                      onChange={e => changeRespuesta('input', e.target.value)}
                    ></input>
                  </div>
                )}

                {!pregunta.foto && !pregunta.input && (
                  <ul className='lista-respuestas lista-values'>
                    {pregunta.respuestas.map((value, idRespuesta) => (
                      <li>
                        <RadioButton
                          text={value}
                          checked={pregunta.respuestaSelected?.idRespuesta === idRespuesta}
                          changeValue={() => changeRespuesta(idRespuesta, value)}
                        />
                      </li>
                    ))}
                  </ul>
                )}

                <div>
                  <div>
                    <div className='btns-examen-next btn-examen-mates'>
                      <Button disabled={false /*+preguntaSelected===1*/} onClick={() => previousPregunta()}>
                        {' '}
                        Back{' '}
                      </Button>
                      <Button className='active-btn' disabled={false} variant='contained' onClick={() => nextPregunta()} color='primary'>
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='stepper-examen'>
            <Stepper nonLinear activeStep={preguntaSelected - 1}>
              <Step>
                <div className={`container-step ${+preguntaSelected === 0 ? 'active-bubble' : ''}`} onClick={() => changeStep(0)}>
                  <div className='icon-done'>i</div>
                </div>
              </Step>
              {Object.entries(currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas).map(([idPregunta, pregunta], key) => {
                return (
                  <Step key={idPregunta}>
                    <div
                      className={`container-step ${+preguntaSelected === +idPregunta ? 'active-bubble' : ''} ${
                        currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[idPregunta].respuestaSelected ? 'pregunta-contestada' : ''
                      }`}
                      onClick={() => changeStep(idPregunta)}
                    >
                      {currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas[idPregunta].respuestaSelected ? (
                        <div className='icon-done'>
                          {/*<DoneRounded />*/}
                          <span>{key+1}</span>
                        </div>
                      ) : (
                        <span>{key+1}</span>
                      )}
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </>
      )}

      {finSection && (
        <div className='resumen-preguntas'>
          <div>
            <h1 className='text-resumen'>Preguntas contestadas</h1>

            <div className='datos-resumen'>
              <div>
                {
                  Object.entries(currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas).filter(
                    ([idPregunta, pregunta]) => pregunta.respuestaSelected
                  ).length
                }
              </div>
              <div>/</div>
              <div>{Object.entries(currentAlumno.inscripcionAlumno.sat.examen.matematicas[type].preguntas).length}</div>
            </div>
            <div className='btns-examen-next'>
              <Button className='back-btn' onClick={() => setFinSection(false)}>
                Back
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ alumnos, global }) => ({
  currentAlumno: alumnos.currentAlumno,
  inscripcion: global.inscripcion
});

const matchDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        modifyCurrentAlumnoExamen
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(PreguntaMatematicas);
