import React, { memo } from 'react'
import ScrollContainer from '../../../../../../Shared/ScrollContainer'
import Evaluacion from './Evaluacion'
import InformacionExtraescolar from './InformacionExtraescolar'
import InformacionExperiencia from './InformacionExperiencia'
import InformacionVoluntariado from './InformacionVoluntariado'
const BecaAcademica = memo(({alumno}) => {

  return(
    <ScrollContainer _class = {`container-table`}>
      <div className='container-informacion-alumno'>

        <Evaluacion
          idAlumno={alumno.id}
          status={ alumno.inscripcionAlumno.inscripcion.beca.academica.evaluacionW2A.status }
          valoracion={ alumno.inscripcionAlumno.inscripcion.beca.academica.evaluacionW2A.valoracion }
          habilidades={ alumno.inscripcionAlumno.inscripcion.beca.academica.evaluacionW2A.habilidades.habilidades }
        />

        <InformacionExtraescolar
          idAlumno={alumno.id}
          tipoActividad={ alumno.inscripcionAlumno.inscripcion.beca.academica.tipoActividad }
          nombreActividad={alumno.inscripcionAlumno.inscripcion.beca.academica.nombreActividad}
          dedicationYears={alumno.inscripcionAlumno.inscripcion.beca.academica.dedicationYears}
          horasSemanales={alumno.inscripcionAlumno.inscripcion.beca.academica.horasSemanales}
          centroActividad={alumno.inscripcionAlumno.inscripcion.beca.academica.centroActividad}
          siguePracticando={alumno.inscripcionAlumno.inscripcion.beca.academica.siguePracticando}
        />

        <InformacionExperiencia
          idAlumno={alumno.id}
          experienciaExtranjero={ alumno.inscripcionAlumno.inscripcion.beca.academica.experienciaExtranjero }
          lugarExperiencia={ alumno.inscripcionAlumno.inscripcion.beca.academica.lugarExperiencia }
          objetivoExperiencia={ alumno.inscripcionAlumno.inscripcion.beca.academica.objetivoExperiencia }
          mesesExperiencia={ alumno.inscripcionAlumno.inscripcion.beca.academica.mesesExperiencia }
          experienciaDescripcion={ alumno.inscripcionAlumno.inscripcion.beca.academica.experienciaDescripcion }
        />

        <InformacionVoluntariado
          idAlumno={alumno.id}
          voluntariado={alumno.inscripcionAlumno.inscripcion.beca.academica.voluntariado }
          practicas={alumno.inscripcionAlumno.inscripcion.beca.academica.practicas}
          voluntariadoDescripcion={ alumno.inscripcionAlumno.inscripcion.beca.academica.voluntariadoDescripcion }
          informacionAdicional={ alumno.inscripcionAlumno.inscripcion.beca.academica.informacionAdicional }
        />

      </div>
    </ScrollContainer>
  )
})

export default BecaAcademica