import React, { useLayoutEffect } from 'react'
import { pathFormularioNewAlumno } from '../../../../../utils/constans';
import styled from 'styled-components'
import { sendEmail } from '../../../../../utils/functions'
const GuardadoCorrectamente = ({ email }) => {

  const refreshPage = () => {
    window.location.reload();
  }

  const handleConfirmationEmail = async () => {
    const response = await sendEmail(email)
    console.log(response)
  }

  useLayoutEffect(() => {
    handleConfirmationEmail()
  }, [])

  return(
    <Container>
      <div className='panel-view-info'>
        <center className='shadow pop-up-cuestionario pr'>

          <div className="btn_cerrar_pop_up pointer" onClick={() => window.location.href=pathFormularioNewAlumno}><i className="material-icons">close</i></div>


          <div className='text-explicativo-panel'>
            <Title>
              ¡Enhorabuena!<br/>
              Tus respuestas se han guardado correctamente.
            </Title>
            <Text>
              En unos días nos pondremos en contacto contigo para coordinar el paso 3: <br/>
              La Prueba de Nivel.<br/>
              Si te has inscrito a través del colegio, tu profesor te indicará el día y hora de la prueba.
            </Text>
            <Info>
              Recuerda: Para <b>conseguir el informe</b> deberás completar <b>los 3 pasos.</b><br/>
              Si tienes dudas inscríbenos un correo a <a href="mailto:hola@unidream.es">hola@unidream.es</a>
            </Info>
          </div>
          
        </center>
      </div>
    </Container>
  )
}

export default GuardadoCorrectamente

const Container = styled.div`
  margin-top: 24px;
`

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  line-height: 26px;
`

const Text = styled.div`
  text-align: justify;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: 600;
`

const Info = styled.div`
  text-align: justify;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 25px;
  text-align: center;
`
