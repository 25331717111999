import React from 'react'

const LoadingPanel = () => {
  return (
    <div id="cargando-formulario-alumnos" className="cargando-formulario" style={{background: '#00000091', display: 'block', position: 'fixed', top: 0, left:0}}>
      <div className='container-loading-firm-alum'>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}

export default LoadingPanel