import React, { memo, useState } from 'react';
import DateCountdown from 'react-date-countdown-timer';
import { localesDateCountdown, localespluralDateCountdown } from '../../../utils/constans';
import { scrollTopAllScreen } from '../../../utils/functions';
import { bindActionCreators } from 'redux';
import { examenIngles, directionsIngles } from '../Data/Ingles';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import RadioButton from '../../Shared/RadioButton';
import { modifyCurrentAlumnoExamen } from '../../../redux/actions/alumnosActions';
import { Stepper, Step, Button, Select, MenuItem } from '@material-ui/core';
import { DoneRounded } from '@material-ui/icons';
import { db } from '../../../utils/firebase';
import _ from 'underscore';
import { ArrowDropDownRounded } from '@material-ui/icons';
const PanelInlgesS1 = memo(({ time, setPanelExamen, currentAlumno, type, inscripcion, setCurrentAlumnoProps, actions }) => {
  const [currentAlumnoState, setCurrentAlumnoState] = useState(currentAlumno);

  const [passageSelected, setPassageSelected] = useState(1);
  const [preguntaSelected, setPreguntaSelected] = useState(0);
  const [finSection, setFinSection] = useState(false);

  const modelo = currentAlumno.inscripcionAlumno.sat.examen.ingles.modelo;

  const changeRespuesta = (idRespuesta, texto) => {
    actions.modifyCurrentAlumnoExamen({
      path: `ingles.${type}.passages.${passageSelected}.preguntas.${preguntaSelected}.respuestaSelected`,
      value: { idRespuesta, texto }
    });
    //todo guardar en bbdd
  };
  const nextPregunta = () => {
    if (+preguntaSelected <= 0) {
      setPreguntaSelected(1);
      return false;
    }

    saveData();

    const preguntasPassage = Object.entries(examenIngles[modelo][type].passages[passageSelected].preguntas);
    const lastPregunta = +preguntasPassage[preguntasPassage.length - 1][0];
    if (lastPregunta === +preguntaSelected && Object.entries(examenIngles[modelo][type].passages).length === +passageSelected) {
      setFinSection(true);
      scrollTopAllScreen();
    } else if (lastPregunta < +preguntaSelected + 1) {
      setPassageSelected(+passageSelected + 1);
      setPreguntaSelected(1);
      scrollTopAllScreen();
    } else {
      setPreguntaSelected(+preguntaSelected + 1);
    }
  };

  const previousPregunta = () => {
    saveData();
    if (+preguntaSelected - 1 === 0 && +passageSelected !== 1) {
      let preguntasPassage = Object.entries(examenIngles[modelo][type].passages[+passageSelected - 1].preguntas);
      let lastPregunta = +preguntasPassage[preguntasPassage.length - 1][0];
      setPassageSelected(+passageSelected - 1);
      setPreguntaSelected(lastPregunta);
      scrollTopAllScreen();
    } else if (+passageSelected === 1 && preguntaSelected === 1) {
      //no hacer nada
    } else {
      setPreguntaSelected(+preguntaSelected - 1);
    }
  };

  const getAllPreguntas = () => {
    let totalPreguntas = 0;
    Object.entries(examenIngles[modelo][type].passages).forEach(([idPassage, passage]) => {
      totalPreguntas += Object.entries(passage.preguntas).length;
    });
    return totalPreguntas;
  };

  const getAllPreguntasRespondidas = () => {
    let totalPreguntasRespondidas = 0;

    Object.entries(currentAlumno.inscripcionAlumno.sat.examen.ingles[type].passages).forEach(([idPassage, passage]) => {
      totalPreguntasRespondidas += Object.entries(passage.preguntas).filter(([idPregunta, pregunta]) => pregunta?.respuestaSelected.idRespuesta !== -1).length;
    });

    return totalPreguntasRespondidas;
  };

  const changeStep = idPregunta => {
    if (+preguntaSelected <= 0) {
      setPreguntaSelected(idPregunta);
      return false;
    }
    saveData();
    setPreguntaSelected(idPregunta);
  };

  const saveData = () => {
    const respuestaSelectedState =
      currentAlumnoState.inscripcionAlumno.sat.examen.ingles[type].passages[passageSelected].preguntas[preguntaSelected]?.respuestaSelected;
    const respuestaSelected = currentAlumno.inscripcionAlumno.sat.examen.ingles[type].passages[passageSelected].preguntas[preguntaSelected]?.respuestaSelected;
    if (!_.isEqual(respuestaSelectedState, respuestaSelected) && respuestaSelected && respuestaSelected.idRespuesta && respuestaSelected.idRespuesta !== -1) {
      let multiPath = {};
      multiPath[
        `Inscripciones/Alumnos/${inscripcion}/${currentAlumno.id}/sat/examen/ingles/${type}/passages/${passageSelected}/preguntas/${preguntaSelected}/respuestaSelected`
      ] = respuestaSelected;
      db.update(multiPath)
        .then(() => {})
        .catch(err => {});
      setCurrentAlumnoState(currentAlumno);
      setCurrentAlumnoProps(currentAlumno);
    }
  };

  const handleChangePassage = ({ target }) => {
    setPassageSelected(+target.value);
    setPreguntaSelected(1);
    scrollTopAllScreen();
    /*setPassageActivo(event.target.value)
    setPassage(examenIngles[1].reading.passages[event.target.value])
    setPreguntas(examenIngles[1].reading.passages[event.target.value].preguntas)
    setpreguntaActiva(1)*/
  };

  const textNextButton = () => {
    var text = 'NEXT';
    const preguntasPassage = Object.entries(examenIngles[modelo][type].passages[passageSelected].preguntas);
    const lastPregunta = +preguntasPassage[preguntasPassage.length - 1][0];
    if (lastPregunta === +preguntaSelected && Object.entries(examenIngles[modelo][type].passages).length === +passageSelected) {
      text = 'NEXT';
    } else if (lastPregunta < +preguntaSelected + 1) {
      text = 'NEXT PASSAGE';
    }
    return text;
  };
  const textBackButton = () => {
    var text = 'BACK';
    if (+preguntaSelected - 1 === 0 && +passageSelected !== 1) {
      text = 'PREVIOUS PASSAGE';
    }

    return text;
  };

  return (
    <div className='panel-ingles all-screen'>
      <div className='contador contador-fixed'>
        <DateCountdown
          dateTo={time.toString()}
          callback={() => setPanelExamen()}
          mostSignificantFigure='min'
          locales={localesDateCountdown}
          locales_plural={localespluralDateCountdown}
        />
      </div>

      {!finSection && (
        <>
          {preguntaSelected <= 0 && (
            <div className='info-ingles-panel'>
              <div className='title-info'>{ReactHtmlParser(directionsIngles[type].text)}</div>
              <div className='directions'>
                <img src={directionsIngles[type].directions} />
              </div>
              <div className='btns-examen-next'>
                <Button className='back-btn' disabled={false} variant='contained' onClick={() => nextPregunta()}>
                  Next
                </Button>
              </div>
            </div>
          )}

          {preguntaSelected > 0 && (
            <>
              <div className='selector-passages'>
                <Select
                  MenuProps={{ id: 'menu-passages' }}
                  IconComponent={ArrowDropDownRounded}
                  renderValue={() => `Passage ${passageSelected}`}
                  onChange={handleChangePassage}
                  displayEmpty
                >
                  {Object.entries(examenIngles[modelo][type].passages).map(([idPassage, passage]) => (
                    <MenuItem value={+idPassage}>Passage {idPassage}</MenuItem>
                  ))}
                </Select>
              </div>

              <div className='content-test'>
                <div className='passage-container'>
                  <h4>{ReactHtmlParser(examenIngles[modelo][type].passages[passageSelected].informacion)}</h4>
                  <p className='texto'>{ReactHtmlParser(examenIngles[modelo][type].passages[passageSelected].texto)}</p>

                  <div className='container-respuestas'>
                    <h4>{ReactHtmlParser(examenIngles[modelo][type].passages[passageSelected].preguntas[preguntaSelected].enunciado)}</h4>
                    <ul className='lista-respuestas'>
                      {Object.entries(examenIngles[modelo][type].passages[passageSelected].preguntas[preguntaSelected].respuestas).map(
                        ([idRespuesta, respuesta]) => {
                          return (
                            <li>
                              <RadioButton
                                text={respuesta.texto}
                                changeValue={() => changeRespuesta(idRespuesta, respuesta.texto)}
                                checked={
                                  currentAlumno.inscripcionAlumno.sat.examen.ingles[type].passages[passageSelected].preguntas[preguntaSelected]
                                    ?.respuestaSelected.idRespuesta === idRespuesta
                                }
                              />
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <div>
                      <div>
                        <div className='btns-examen-next'>
                          <Button disabled={false /*+passageSelected===1 && +preguntaSelected===1*/} onClick={() => previousPregunta()}>
                            {' '}
                            {textBackButton()}{' '}
                          </Button>
                          <Button className='active-btn' disabled={false} variant='contained' onClick={() => nextPregunta()} color='primary'>
                            {textNextButton()}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='stepper-examen'>
            <Stepper nonLinear activeStep={preguntaSelected - 1}>
              <Step>
                <div className={`container-step ${+preguntaSelected === 0 ? 'active-bubble' : ''}`} onClick={() => changeStep(0)}>
                  <div className='icon-done'>i</div>
                </div>
              </Step>
              {Object.entries(examenIngles[modelo][type].passages[passageSelected].preguntas).map(([idPregunta, pregunta]) => {
                return (
                  <Step key={pregunta.indicePregunta}>
                    <div
                      className={`container-step ${+preguntaSelected === +idPregunta ? 'active-bubble' : ''} ${
                        currentAlumno.inscripcionAlumno.sat.examen.ingles[type].passages[passageSelected].preguntas[idPregunta]?.respuestaSelected.idRespuesta >
                        0
                          ? 'pregunta-contestada'
                          : ''
                      }`}
                      onClick={() => changeStep(idPregunta)}
                    >
                      {currentAlumno.inscripcionAlumno.sat.examen.ingles[type].passages[passageSelected].preguntas[idPregunta]?.respuestaSelected.idRespuesta >
                      0 ? (
                        <div className='icon-done'>
                          {/*<DoneRounded />*/}
                          <span>{pregunta.indicePregunta}</span>
                        </div>
                      ) : (
                        <span>{pregunta.indicePregunta}</span>
                      )}
                    </div>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </>
      )}

      {finSection && (
        <div className='resumen-preguntas'>
          <div>
            <h1 className='text-resumen'>Preguntas contestadas</h1>

            <div className='datos-resumen'>
              <div>{getAllPreguntasRespondidas()}</div>
              <div>/</div>
              <div>{getAllPreguntas()}</div>
            </div>
            <div className='btns-examen-next'>
              <Button className='back-btn' onClick={() => setFinSection(false)}>
                Back
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const mapStateToProps = ({ alumnos, global }) => ({
  currentAlumno: alumnos.currentAlumno,
  inscripcion: global.inscripcion
});

const matchDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        modifyCurrentAlumnoExamen
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(PanelInlgesS1);
