import fb from 'firebase';

//PRODUCCION
// var config = {
//   apiKey: "AIzaSyCx57BcABvX91agfwOHBrG2An_IgWtAyOU",
//   authDomain: "w2a-2-0.firebaseapp.com",
//   databaseURL: "https://w2a-2-0.firebaseio.com",
//   projectId: "w2a-2-0",
//   storageBucket: "w2a-2-0.appspot.com",
//   messagingSenderId: "562268777794",
//   appId: "1:562268777794:web:788dd303543d60184facdb"
// };

//DESARROLLO
/*var config = {
  apiKey: "AIzaSyBxijHxzmbHXCbye7ivy6Wo7lGyIJgM63s",
  authDomain: "w2americandream-desarrollo.firebaseapp.com",
  databaseURL: "https://w2americandream-desarrollo.firebaseio.com",
  projectId: "w2americandream-desarrollo",
  storageBucket: "w2americandream-desarrollo.appspot.com",
  messagingSenderId: "208081448290"
};*/

// //PRODUCCION UNIDREAM 2020-2021
// var config = {
//   apiKey: "AIzaSyA6jS9vGB8UiROFPZ0i-uIgvIza8nxdMb0",
//   authDomain: "unidream-63670.firebaseapp.com",
//   databaseURL: "https://unidream-63670-default-rtdb.firebaseio.com",
//   projectId: "unidream-63670",
//   storageBucket: "unidream-63670.appspot.com",
//   messagingSenderId: "388418201940",
//   appId: "1:388418201940:web:d78aa5154eef4c585140c1",
//   measurementId: "G-9BDPQ0NJ08"
// }

//PRODUCCION UNIDREAM 2021-2022
var config = {
  apiKey: "AIzaSyBxgTCTk7nBLj8SUHHuP34plGBy1G9MCKM",
  authDomain: "unidream-2021-2022.firebaseapp.com",
  databaseURL: "https://unidream-2021-2022-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "unidream-2021-2022",
  storageBucket: "unidream-2021-2022.appspot.com",
  messagingSenderId: "529313931273",
  appId: "1:529313931273:web:fa0f13bd31543be02118d8",
  measurementId: "G-HY6B540T1G"
};

const firebase = fb.initializeApp(config)

export const db = firebase.database().ref()
export const signOut = () => firebase.auth().signOut()

export default firebase 