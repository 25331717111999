import React, { useState } from 'react'
import { opcionesTipoDeporte, opcionesDivisionDeporte, opcionesFederado } from '../../../../../../utils/constans'
import ListaOpciones from '../../../Shared/ListaOpciones'
import { onFocusInscripciones, onBlurInscripciones } from '../../../../../../utils/functions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import Checkbox from '../../../../../W2AViews/Components/Checkbox'

const Autorizacion = (props) => {

  const { errors } = props


  const callbackCheckItem = (json) => {
    if(json.id==="colaboraciones"){
      props.actions.modifyCurrentAlumno({value:json.value, path:'inscripcionAlumno.inscripcion.beca.academica.colaboraciones'})
    }else if(json.id==="imagen"){
      props.actions.modifyCurrentAlumno({value:json.value, path:'inscripcionAlumno.inscripcion.beca.academica.imagen'})
    }else if(json.id==="terminos"){
      props.actions.modifyCurrentAlumno({value:json.value, path:'inscripcionAlumno.inscripcion.beca.academica.terminos'})
    }
  }

  return(
    <div>
      <div className="display_inline_grid div_terminos_colegio">
        
        {/* PUBLICACION DE IMAGEN  */}
        <Checkbox span_checkbox_class={false} callbackCheckItem={callbackCheckItem} json={{id:'colaboraciones'}} checked={props.colaboraciones} 
          text={'Autorizo la recepción de comunicaciones comerciales por parte de Unidream y entidades colaboradoras del Programa Unidream, tales como Universidades, residencias de estudiantes y deportistas, así como otras entidades colaboradoras del Programa que prestan servicios relacionados con el ámbito universitario, educativo y laboral a nivel nacional e internacional.'} 
        />
        
        {/* PUBLICACION DE IMAGEN  */}
        <Checkbox span_checkbox_class={false} callbackCheckItem={callbackCheckItem} json={{id:'imagen'}} checked={props.imagen} 
          text={'Autorizo la publicación de mi imagen, que podrá ser capturada durante el desarrollo de los eventos organizados dentro del Programa Unidream, con objeto de promocionar las actividades y los valores que componen el Programa.'} 
        />

        {/* AVISO LEGAL   */}
        <Checkbox clss_i={errors.terminos?'obligatorio_color':''} span_checkbox_class={false} callbackCheckItem={callbackCheckItem} json={{id:'terminos'}} tipo={'link'} checked={props.terminos} text={'Acepto la '} text_1={'política de privacidad'} link_1={'https://unidream.es/declaracion-de-privacidad/'} text_2=' y las ' text_3='bases de participación' link_2={'https://unidream.es/bases-de-participacion/'}  />

      </div>
    </div>
  )
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      modifyCurrentAlumno
    }, dispatch)	
	};
}; 

export default connect(null, matchDispatchToProps)(Autorizacion);
