import React, { useState } from 'react'
import HeaderW2A from '../../W2AViews/HeaderW2A'
import FooterW2A from '../../W2AViews/FooterW2A'
import DatosColegio from './DatosColegio'
import Responsale from './Responsale'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { saveColegio, modifyCurrentColegio } from '../../../redux/actions/colegiosActions';
import ErrorFormulario from '../../AreaAlumno/Inscripcion/Shared/ErrorFormulario'
import { ocultarCargandoFormAlumnos, visualizarCargandoFormAlumnos } from '../../../utils/functions'
import GuardadoCorrectamente from './GuardadoCorrectamente'
import LogosContainer from '../../Shared/LogosContainer'
import styled from 'styled-components'
import Checkbox from '../../W2AViews/Components/Checkbox'

const Inscripcion = ({currentColegio, actions}) => {

  const [errors, setErrors] = useState({})
  const [showMensaje, setShowMensaje] = useState(false)
  const [guardadoCorrectamente, setGuardadoCorrectamente] = useState(false)
  
  const handleInscripcion = () => {
    visualizarCargandoFormAlumnos({bottom: true})
    let errors = currentColegio.checkColegio()
    console.log()
    setErrors(errors)
    if(Object.keys(errors).length>0){
      setShowMensaje(true)
      ocultarCargandoFormAlumnos()
      return false
    }

    saveData()
  }

  const saveData = async() => {
    //guardar en base de datos 
    let ok = await actions.saveColegio()
    ocultarCargandoFormAlumnos()
    if(ok){
      setGuardadoCorrectamente(true)
    }else{
      console.log("Error al guardar en bbdd");
    }
  }

  const callbackCheckItem = (json) => {
    if(json.id==="terminos"){
      actions.modifyCurrentColegio({value:json.value, path:'terminos'})
    }
  }

  return(
    <div className='bg-w2a'>
      <HeaderW2A categoria='Inscripción de colegios' />
        {guardadoCorrectamente && 
          <GuardadoCorrectamente email={currentColegio.responsables[0].email} />
        }
        {!guardadoCorrectamente && 
          <div className="formulario_fase_comun pr">
            {/* <div className='title-panel-form-alumnos'>Inscripción de colegios</div> */}
            <DatosColegio
              nombre={currentColegio.nombre}
              provincia={currentColegio.provincia ?? ''}
              participacionAnterior={currentColegio.participacionAnterior}
              errors={errors}
            />

            <h3 className="title_form">RESPONSABLES</h3>
            <p>
            En esta sección os pediremos datos de contacto de dos responsables del programa en el colegio. Los datos se tratarán con total confidencialidad. En la sección de número de teléfono esperamos que anotéis los teléfonos móviles. Pese a que la comunicación se hará mayoritariamente por email, tener un número de teléfono móvil puede facilitar mucho la comunicación con el colegio.
            </p>
            <Responsale 
              num={0}
              nombre={currentColegio.responsables[0].nombre}
              apellidos={currentColegio.responsables[0].apellidos}
              email={currentColegio.responsables[0].email}
              telefonoMovil={currentColegio.responsables[0].telefonoMovil}
              telefonoFijo={currentColegio.responsables[0].telefonoFijo}
              cargo={currentColegio.responsables[0].cargo}
              horarioPreferente={currentColegio.responsables[0].horarioPreferente}
              errors={errors}
            />

            <Responsale 
              num={1}
              nombre={currentColegio.responsables[1].nombre}
              apellidos={currentColegio.responsables[1].apellidos}
              email={currentColegio.responsables[1].email}
              telefonoMovil={currentColegio.responsables[1].telefonoMovil}
              telefonoFijo={currentColegio.responsables[1].telefonoFijo}
              cargo={currentColegio.responsables[1].cargo}
              horarioPreferente={currentColegio.responsables[1].horarioPreferente}
              errors={errors}
            />
            
            <Title>Información básica sobre protección de datos (RGPD|LOPD-gdd)</Title>
            <Text>Responsable: UNIDREAMS, S.L</Text>
            <Text>Finalidad: Gestión y tramitación de la inscripción de los colegios participantes.</Text>
            <Text>Legitimación: Consentimiento del interesado.</Text>
            <Text>Destinatarios: No se cederán datos a terceros, salvo obligación legal</Text>
            <Text>Derechos: Acceder, rectificar, limitar y suprimir los datos, así como presentar una reclamación ante la autoridad nacional de control, como se explica en la información adicional.</Text>
            <Text>Información Adicional: Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra “Política de Privacidad”.</Text>
            <Terminos>
              <Checkbox callbackCheckItem={callbackCheckItem} clss_i={errors.terminos?'obligatorio_color':''} span_checkbox_class={false} json={{id:'terminos'}} tipo={'link'} checked={currentColegio.terminos} text={'He leído y acepto la '} text_1={'Política de privacidad'} link_1={'https://unidream.es/declaracion-de-privacidad/'} text_2=' de UNIDREAM'  />
            </Terminos>

            {showMensaje &&
                <>
                    <br/>
                    <ErrorFormulario updateError={()=>setShowMensaje(false)} />
                </>
            }

            <div id="cargando-formulario-alumnos" className="cargando-formulario" >
              <div className='container-loading-firm-alum'>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
            </div>

            <div className="btn_send_form pointer noselect" onClick={handleInscripcion}>Inscribirse</div>
            
            <LogosContainer />

          </div>
        }
      <FooterW2A />
    </div>
  ) 
}


const mapStateToProps = ({ colegios }) => {
  return {
    currentColegio: colegios.currentColegio
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      saveColegio,
      modifyCurrentColegio
    }, dispatch)	
	};
}; 
export default connect(mapStateToProps, matchDispatchToProps)(Inscripcion);

const Title = styled.div`
  font-size: 18px;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 12px;
`
const Text = styled.div``

const Terminos = styled.div`
  margin-top: 12px;
  margin-bottom: 36px;
`