import React from 'react'
import w2aLogo from '../../images/logos/w2aLogo.png'
import endesaLogo from '../../images/logos/endesaLogo.png'
import dominosLogo from '../../images/logos/dominos.png'
import realesegurosLogo from '../../images/logos/realeseguros.png'
import ueLogo from '../../images/logos/ue.png'
import insudpharma from '../../images/logos/insudpharma.png'
import styled from 'styled-components'

const LogosContainer = () => {
  return(
    <Container>
      <Logo>
        <Image src={w2aLogo} />
      </Logo>
      <Logo>
        <Image src={endesaLogo} />
      </Logo>
      <Logo>
        <Image src={ueLogo} />
      </Logo>
      <Logo>
        <Image src={realesegurosLogo} />
      </Logo>
      <Logo>
        <Image src={dominosLogo} />
      </Logo>
      <Logo>
        <Image src={insudpharma} />
      </Logo>
    </Container>
  )
}

export default LogosContainer

const Container = styled.div`
 margin-top: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`

const Logo = styled.div`
  min-width: 240px;
  max-width: 240px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
    padding-right: 25px;
  flex: 1;
`

const Image = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 100px;
`