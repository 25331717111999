import React, { useState, useEffect, memo } from 'react';
import Input from '../../../../../Shared/Input';
import InputMenu from '../../../../../Shared/InputMenu';
import Switch from '../../../../../Shared/Switch';
import UpdateStateInputs from '../../../../../Shared/UpdateStateInputs';
import { isEmail, isTelefono, isValidFechaNacimiento, cleanDNI, isCorrectDni, validFecha } from '../../../../../../utils/functions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/globalActions';
import { db } from '../../../../../../utils/firebase';
import _ from 'underscore';
import { genderOptions } from '../../../../../../utils/constans';

const InformacionPersonal = memo(props => {
  const [edited, setEdited] = useState(false);
  const [state, setState] = useState({ ...props });

  useEffect(() => {
    setEdited(!_.isEqual(state, props));
  }, [state]);
  useEffect(() => {
    setState({ ...props });
  }, [props]);

  const saveData = () => {
    var validDni = isCorrectDni(state.dni);
    if (validDni) validDni = !isRepeatDNI();
    if (
      !validDni ||
      !state.nombre.trim() ||
      !isValidFechaNacimiento(state.fechaNacimiento) ||
      !state.dni.trim() ||
      !state.apellidos.trim() ||
      !isEmail(state.email) ||
      !isTelefono(state.telefono)
    ) {
      props.actions.setNotification({ visibility: true, text: 'Exiten errores en los campos', status: 'close', moment: Date.now() });
      return false;
    }
    var multiPath = {};
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/nombre`] = state.nombre.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/apellidos`] = state.apellidos.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/email`] = state.email.trim().toLowerCase();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/telefono`] = state.telefono.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/dni`] = state.dni.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/fechaNacimiento`] = state.fechaNacimiento.trim();
    multiPath[`Alumnos/${props.idAlumno}/informacionAlumno/sexo`] = state.sexo.trim();
    db.update(multiPath)
      .then(() => props.actions.setNotification({ visibility: true, text: 'Actualizado correctamente', status: 'done', moment: Date.now() }))
      .catch(err => props.actions.setNotification({ visibility: true, text: 'Ha ocurrido algún error', status: 'close', moment: Date.now() }));
  };

  const isRepeatDNI = () =>
    Object.entries(props.alumnos).some(
      ([idAlumno, alum]) => alum.id !== props.alumno.id && alum.informacionAlumno.dni.toUpperCase() === state.dni.toUpperCase()
    );

  return (
    <div className='sub-container-informacion-alumno'>
      {edited ? <UpdateStateInputs saveData={saveData} undoData={() => setState({ ...props })} /> : null}

      <p className='title-informacion-alumno'>1. Información personal</p>

      <div className='col-2-input'>
        <Input
          title='Nombre'
          _class_container={!state.nombre.trim() ? 'error-form-input' : null}
          text={state.nombre}
          changeValue={nombre => setState({ ...state, nombre })}
        />
        <Input
          title='Apellidos'
          _class_container={!state.apellidos.trim() ? 'error-form-input' : null}
          text={state.apellidos}
          changeValue={apellidos => setState({ ...state, apellidos })}
        />
      </div>

      <div className='col-2-input'>
        <Input
          type='block'
          /*type='email'*/ _class_container={!state.email.trim() || !isEmail(state.email) ? 'error-form-input' : null}
          title='Dirección de correo'
          text={state.email}
          changeValue={email => setState({ ...state, email: email.toLowerCase() })}
        />
        <Input
          type='phone'
          title='Teléfono móvil'
          _class_container={state.telefono.trim() === '' || !isTelefono(state.telefono) ? 'error-form-input' : null}
          _class_input='dni-input'
          text={state.telefono}
          changeValue={telefono => setState({ ...state, telefono })}
        />
      </div>

      <div className='col-2-input'>
        <Input
          title='Dni'
          _class_container={!state.dni.trim() || !isCorrectDni(state.dni) || isRepeatDNI() ? 'error-form-input' : null}
          _class_input='dni-input'
          text={state.dni}
          changeValue={dni => {
            setState({ ...state, dni: cleanDNI(dni) });
          }}
        />
        <Input
          type='block'
          title='Contraseña'
          /*_class_container={state.password.trim()===''?'error-form-input':null}*/ text={
            '*************' /*state.password*/
          } /*changeValue={password=>{setState({...state, password})}}*/
        />
      </div>

      <div className='col-2-input'>
        <Input
          title='Fecha de nacimiento'
          _class_container={!state.fechaNacimiento.trim() || !isValidFechaNacimiento(state.fechaNacimiento) ? 'error-form-input' : null}
          text={state.fechaNacimiento}
          changeValue={newFechaNacimiento => setState({ ...state, fechaNacimiento: validFecha(newFechaNacimiento, state.fechaNacimiento) })}
        />

        <InputMenu list={genderOptions} title='Sexo' text={state.sexo} changeValue={sexo => setState({ ...state, sexo })} />
      </div>

      <div className='col-2-input'>
        <div className='display_flex container-simple-input pdd-top-40 flex_1'>
          <div className='title-input align-center mg-right-10 pdd-v-0 text-align-left'>Autoriza la recepción de comunicaciones comerciales:</div>
          <span className='options-switch'>NO</span>
          <Switch class_div='switch-table' json={{ id: 'colaboraciones' }} value={state.colaboraciones} callbackSwitch={null} />
          <span className='options-switch'>SI</span>
        </div>

        <div className='display_flex container-simple-input pdd-top-40 flex_1 '>
          <div className='title-input align-center mg-right-10 pdd-v-0 text-align-left'>Autoriza la publicación de su imagen:</div>
          <span className='options-switch'>NO</span>
          <Switch class_div='switch-table' json={{ id: 'imagen' }} value={state.imagen} callbackSwitch={null} />
          <span className='options-switch'>SI</span>
        </div>
      </div>
    </div>
  );
});

const mapStateToProps = ({ alumnos }) => {
  return {
    alumnos: alumnos.alumnos
  };
};

const matchDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        setNotification
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(InformacionPersonal);
