import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
const PanelInformativo = ({setPanelSelected}) => {
  return(
    <div className='panel-view-info'>
      <center className='shadow pop-up-cuestionario'>
        <div className='text-explicativo-panel'>
          <Title>Bienvenid@ a la página de inscripción de Unidream</Title>
          Para realizar tu inscripción al programa y recibir tu informe de orientación totalmente gratuito, deberás completar los siguientes tres pasos:
          <br /><br />
          <b>Paso 1:</b> Rellenar el Formulario de Inscripción (10 minutos)
          <br />
          <b>Paso 2:</b> Completar un cuestionario online (25 minutos)
          <br />
          <b>Paso 3:</b> Realizar una prueba de Nivel (2 horas) 
          <br />
          <br />
          No te preocupes. No tienes que hacer los tres pasos seguidos. Podrás hacerlos en momentos diferentes cuando mejor te convenga.
          <br />
          <br />
          <Title>PASO 1: Rellena el formulario online</Title>
          Esta inscripción te tomara 10 minutos. La puedes realizar con tu móvil, tablet u ordenador. ¡A por ello! 
        </div>
        <div className='div-opciones-inscripcion'>
          <Link to={'/area-alumno'}>
            <div className='btn_send_form btn-volver-form'>Iniciar sesión</div>
          </Link>
          <div className='btn_send_form' onClick={setPanelSelected}>Empezar</div>
        </div>
      </center>
    </div>
  )
}

export default PanelInformativo

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  line-height: 22px;
`