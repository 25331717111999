import firebase, { db } from './firebase';
import $ from 'jquery';
import pdf from 'pdfjs';
import axios from 'axios';
import { examenIngles, respuestasIngles } from '../components/ExamenSat/Data/Ingles';
import { examenMatematicas, respuestasMatematicas } from '../components/ExamenSat/Data/Matematicas';
import { rawScoreSat, satScoreAmerica, satScoreEuropa, universitiesAmerica, universitiesEuropa, profesionesRama, ramasGenerales } from './constans';
import moment from 'moment';

let fontArial, fontArialBold;

export const isLogged = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user?.email) return resolve({ email: user.email });
      return reject(403);
    });
  });
};

export const isRepeatedDni = (dni, idAlumno) => {
  return new Promise((resolve, reject) => {
    db.child('Alumnos')
      .orderByChild('informacionAlumno/dni')
      .equalTo(dni)
      .limitToFirst(1)
      .once('value', snapshot => {
        if (snapshot.exists()) {
          if (idAlumno && idAlumno === Object.entries(snapshot.val())[0][0]) {
            return resolve(false);
          }
          return resolve(true);
        }
        return resolve(false);
      });
  });
};

export const isRepeatedEmail = email => {
  return new Promise((resolve, reject) => {
    db.child('Alumnos')
      .orderByChild('informacionAlumno/email')
      .equalTo(email)
      .limitToFirst(1)
      .once('value', snapshot => {
        if (snapshot.exists()) {
          return resolve(true);
        }
        return resolve(false);
      });
  });
};

export const getInscripcion = () => {
  const year = moment().format('YYYY');
  const month = moment().format('M');
  //Descomentar linea de abajo para que pueda coger las nuevas inscripciones cuando es septiembre
  //if (+month >= 9) return `${year}-${+year + 1}`;
  return '2021-2022';
  return `${+year - 1}-${year}`;
};

export const cleanText = text => {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const isNumber = value => {
  value = value.trim();
  value = value.replace(',', '.');
  if (isNaN(value)) {
    return false;
  }
  return true;
};

export const isEmail = value => {
  if (!value.includes('@')) return false;
  value = value.substring(value.indexOf('@'), value.length);
  if (value.includes('.')) {
    return true;
  }
  return false;
};

export const isTelefono = value => {
  value = value.trim();
  if (value.startsWith('+')) {
    value = value.replace('+', '');
  }
  value = value.replace('.', '');
  value = value.replace(/ /g, '');
  if (isNaN(value)) {
    return false;
  }
  if (value.length < 9) return false;
  return true;
};

export const cleanDNI = value => {
  value = value.replace(/ /g, '').trim().toUpperCase();
  return value;
};
export const isCorrectDni = value => {
  value = value.trim();
  if (value.length >= 9) {
    return true;
  }
  return false;
};

export const isValidFechaNacimiento = fechaNacimiento => {
  if (fechaNacimiento.length === 10 || fechaNacimiento.length === 8) {
    return true;
  }
  return false;
};

export const validFecha = (num, oldValue) => {
  const valorFijo = num.substring(0, num.length - 1);
  if (oldValue.endsWith('/') && !num.endsWith('/') && num.length < oldValue.length) {
    num = num.substring(0, num.length - 1);
  }
  num = num.trim();
  num = num.replace(',', '');
  num = num.replace('.', '');
  num = num.replace('-', '');
  num = num.replace(/\//g, '');

  if (!isNaN(+num) && num.length <= 8) {
    if (num.length >= 2) {
      num = num.substring(0, 2) + '/' + num.substring(2, num.length);
      if (num.length >= 5) {
        num = num.substring(0, 5) + '/' + num.substring(5, num.length);
      }
    }

    return num;
  }
  return valorFijo;
};

export const getNumber = value => {
  value = value.toString();
  value = value.trim();
  value = value.replace(',', '.');
  return value;
};

export const cleanProtocol = value => {
  return value;
};

export const validHora = (num, oldValue) => {
  const valorFijo = num.substring(0, num.length - 1);
  if (oldValue.endsWith(':') && !num.endsWith(':') && num.length < oldValue.length) {
    num = num.substring(0, num.length - 1);
  }

  num = num.trim();
  num = num.replace(',', '');
  num = num.replace('.', '');
  num = num.replace('-', '');
  num = num.replace(/:/g, '');

  if (!isNaN(+num) && num.length <= 4) {
    if (num.length >= 2) {
      num = num.substring(0, 2) + ':' + num.substring(2, num.length);
    }
    return num;
  }

  return valorFijo;
};

export const generateInglesExamen = () => {
  const modelo = Math.floor(Math.random() * 3) + 1;
  //const modelo = 3;

  let reading = { passages: {} };
  Object.entries(examenIngles[modelo].reading.passages).forEach(([idPassage, passage]) => {
    Object.entries(passage.preguntas).forEach(([idPregunta, pregunta]) => {
      if (!reading.passages[idPassage]) reading.passages[idPassage] = { preguntas: {} };
      if (!reading.passages[idPassage].preguntas[idPregunta])
        reading.passages[idPassage].preguntas[idPregunta] = {
          respuestaSelected: { idRespuesta: -1, texto: '' }
        };
    });
  });

  let writing = { passages: {} };
  Object.entries(examenIngles[modelo].writing.passages).forEach(([idPassage, passage]) => {
    Object.entries(passage.preguntas).forEach(([idPregunta, pregunta]) => {
      if (!writing.passages[idPassage]) writing.passages[idPassage] = { preguntas: {} };
      if (!writing.passages[idPassage].preguntas[idPregunta])
        writing.passages[idPassage].preguntas[idPregunta] = {
          respuestaSelected: { idRespuesta: -1, texto: '' }
        };
    });
  });

  const examen = {
    modelo,
    reading,
    writing
  };

  //examenIngles[indice].reading.passages

  /*const examen = {
    modelo:indice,
    reading: examenIngles[indice].reading,
    writing: examenIngles[indice].writing
  }*/
  return examen;
};

export const generateMatematicasExamen = indiceParte => {
  let preguntasMatematicas = {};
  Object.entries(examenMatematicas[indiceParte].preguntas).map(([idPregunta, pregunta]) => {
    let indiceTipo = Math.floor(Math.random() * 3) + 1;
    if (pregunta.tipo[indiceTipo].respuestas) {
      let respuestasOriginales = pregunta.tipo[indiceTipo].respuestas;
      respuestasOriginales = respuestasOriginales.sort(() => Math.floor(Math.random() - 0.5));
      preguntasMatematicas = {
        ...preguntasMatematicas,
        [idPregunta]: {
          ...pregunta.tipo[indiceTipo],
          tipo: indiceTipo,
          respuestas: respuestasOriginales
        }
      };
    } else {
      preguntasMatematicas = {
        ...preguntasMatematicas,
        [idPregunta]: {
          ...pregunta.tipo[indiceTipo],
          tipo: indiceTipo
        }
      };
    }
  });
  return preguntasMatematicas;
};

export const onFocusInscripciones = e => {
  $(e.currentTarget).closest('.formulario_add_colegios').find('.label_form_active').removeClass('label_form_active');
  $(e.currentTarget).siblings('.label_form').addClass('label_form_active');
};
export const onBlurInscripciones = e => {
  $(e.currentTarget).siblings('.label_form').removeClass('label_form_active');
};

export const validarDni = text => {
  text = text
    .toUpperCase()
    .trim()
    .replace(/[^a-z0-9]/gi, '');
  return text;
};

export const replacePlusDot = text => {
  text = text.replace(/\+\./g, '');
  return text;
};
export const deletePlus = text => {
  text = text.replace(/\+/g, '');
  return text;
};
export const deleteDots = text => {
  text = text.replace(/\./g, '');
  return text;
};

export const validarTelefono = valor => {
  const valorFijo = valor.substring(0, valor.length - 1);
  valor = valor.trim();
  var firstCaracter = valor.substring(0, 1);
  if (firstCaracter === '+' || !isNaN(firstCaracter)) {
    valor = replacePlusDot(valor);
    valor = deletePlus(valor);

    //verificar puntos
    var anterior = 0;
    var newValor = valor.charAt(anterior);
    for (var i = 1; i < valor.length; i++) {
      if (!(valor.charAt(anterior) === '.' && valor.charAt(i) === '.')) {
        newValor += valor.charAt(i);
      }
      anterior = i;
    }
    valor = deleteDots(valor);

    if (!isNaN(+valor)) {
      if (firstCaracter === '+') newValor = '+' + newValor;
      return newValor;
    }
  }

  return valorFijo;
};

export const validFechaNacimiento = (num, oldValue) => {
  const valorFijo = num.substring(0, num.length - 1);
  if (oldValue.endsWith('/') && !num.endsWith('/') && num.length < oldValue.length) {
    num = num.substring(0, num.length - 1);
  }
  num = num.trim();
  num = num.replace(',', '');
  num = num.replace('.', '');
  num = num.replace('-', '');
  num = num.replace(/\//g, '');

  if (!isNaN(+num) && num.length <= 8) {
    if (num.length >= 2) {
      num = num.substring(0, 2) + '/' + num.substring(2, num.length);
      if (num.length >= 5) {
        num = num.substring(0, 5) + '/' + num.substring(5, num.length);
      }
    }

    return num;
  }
  return valorFijo;
};

export const validNumber = num => {
  const valorFijo = num.substring(0, num.length - 1);
  num = num.trim();
  num = num.replace(',', '.');

  if (!isNaN(+num) && num.length < 6) {
    return num;
  }
  return valorFijo;
};

export const ocultarCargandoFormAlumnos = obj => {
  $('#cargando-formulario-alumnos').removeClass('display_block');
  if (obj && obj.bottom) {
    $('#cargando-formulario-alumnos>div.container-loading-firm-alum').removeClass('moveToBottomCargandoIcon');
  }
};

export const visualizarCargandoFormAlumnos = obj => {
  $('#cargando-formulario-alumnos').addClass('display_block');
  if (obj && obj.bottom) {
    $('#cargando-formulario-alumnos>div.container-loading-firm-alum').addClass('moveToBottomCargandoIcon');
  }
};

export const spaces = num => {
  var text = '';
  for (var i = 0; i < num; i++) {
    text = text + ' ';
  }
  return text;
};
export const scrollTopAllScreen = () => {
  $('.all-screen').animate({ scrollTop: 0 }, 500);
};

export const getNotasSat = examen => {
  let inglesReadingCorrectas = 0;
  let inglesWritingCorrectas = 0;
  let modeloIngles = examen?.ingles?.modelo;
  let matematicasCorrectas = 0;

  Object.entries(examen.ingles.reading.passages).map(([idPassage, passage]) => {
    Object.entries(passage.preguntas).map(([idPregunta, pregunta]) => {
      let respuestaCorrecta = respuestasIngles[modeloIngles].reading.passages[idPassage].preguntas[idPregunta].idRespuestaCorrecta;
      if (pregunta?.respuestaSelected?.idRespuesta === respuestaCorrecta) inglesReadingCorrectas++;
    });
  });

  Object.entries(examen.ingles.writing.passages).map(([idPassage, passage]) => {
    Object.entries(passage.preguntas).map(([idPregunta, pregunta]) => {
      let respuestaCorrecta = respuestasIngles[modeloIngles].writing.passages[idPassage].preguntas[idPregunta].idRespuestaCorrecta;
      if (pregunta?.respuestaSelected?.idRespuesta === respuestaCorrecta) inglesWritingCorrectas++;
    });
  });

  Object.entries(examen.matematicas).map(([idParte, parte]) => {
    Object.entries(parte.preguntas).map(([idPregunta, pregunta]) => {
      let tipoPregunta = pregunta?.tipo;
      let respuestaCorrecta = respuestasMatematicas[idParte].preguntas[idPregunta].tipo[tipoPregunta].respuestaCorrecta;
      if (pregunta?.respuestaSelected?.value === respuestaCorrecta) matematicasCorrectas++;
    });
  });

  let rawInglesReading = rawScoreSat[inglesReadingCorrectas].reading;
  let rawInglesWriting = rawScoreSat[inglesWritingCorrectas].writing;
  let rawMatematicas = Math.floor(matematicasCorrectas * 1.5);

  let totalIngles = 10 * rawInglesReading + 10 * rawInglesWriting;
  let totalMatematicas = rawScoreSat[rawMatematicas].maths;

  return {
    ingles: totalIngles,
    matematicas: totalMatematicas
  };
};

export const b64toBlob = dataURI => {
  let byteString = atob(dataURI);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'application/pdf' });
};

export const getDetalleResultadosSat = examen => {
  let detalleSat = {
    ingles: {
      comprension: {
        aciertos: 0,
        fallos: 0
      },
      retorica: {
        aciertos: 0,
        fallos: 0
      },
      sintesis: {
        aciertos: 0,
        fallos: 0
      },
      gramatica: {
        aciertos: 0,
        fallos: 0
      },
      vocabulario: {
        aciertos: 0,
        fallos: 0
      },
      writing: {
        aciertos: 0,
        fallos: 0
      }
    },
    matematicas: {
      algebra: {
        aciertos: 0,
        fallos: 0
      },
      analisis: {
        aciertos: 0,
        fallos: 0
      },
      avanzadas: {
        aciertos: 0,
        fallos: 0
      },
      trigonometria: {
        aciertos: 0,
        fallos: 0
      }
    }
  };

  let modeloIngles = examen?.ingles?.modelo;

  Object.entries(examen.ingles.reading.passages).map(([idPassage, passage]) => {
    Object.entries(passage.preguntas).map(([idPregunta, pregunta]) => {
      let respuestaCorrecta = respuestasIngles[modeloIngles].reading.passages[idPassage].preguntas[idPregunta].idRespuestaCorrecta;
      let rama = respuestasIngles[modeloIngles].reading.passages[idPassage].preguntas[idPregunta].rama;
      if (pregunta?.respuestaSelected?.idRespuesta === respuestaCorrecta) {
        detalleSat.ingles[`${rama}`].aciertos++;
      } else {
        detalleSat.ingles[`${rama}`].fallos++;
      }
    });
  });

  Object.entries(examen.ingles.writing.passages).map(([idPassage, passage]) => {
    Object.entries(passage.preguntas).map(([idPregunta, pregunta]) => {
      let respuestaCorrecta = respuestasIngles[modeloIngles].writing.passages[idPassage].preguntas[idPregunta].idRespuestaCorrecta;
      let rama = respuestasIngles[modeloIngles].writing.passages[idPassage].preguntas[idPregunta].rama;
      if (pregunta?.respuestaSelected?.idRespuesta === respuestaCorrecta) {
        detalleSat.ingles[`${rama}`].aciertos++;
      } else {
        detalleSat.ingles[`${rama}`].fallos++;
      }
    });
  });

  Object.entries(examen.matematicas).map(([idParte, parte]) => {
    Object.entries(parte.preguntas).map(([idPregunta, pregunta]) => {
      let tipoPregunta = pregunta?.tipo;
      let respuestaCorrecta = respuestasMatematicas[idParte].preguntas[idPregunta].tipo[tipoPregunta].respuestaCorrecta;
      let rama = respuestasMatematicas[idParte].preguntas[idPregunta].rama;
      if (pregunta?.respuestaSelected?.value === respuestaCorrecta) {
        detalleSat.matematicas[`${rama}`].aciertos++;
      } else {
        detalleSat.matematicas[`${rama}`].fallos++;
      }
    });
  });

  return detalleSat;
};

const getImgPagePdf = async pagina => {
  const blobPage1 = b64toBlob(pagina);
  const buffer = await blobPage1.arrayBuffer();
  const img = new pdf.Image(buffer);
  return img;
};

export const generatePDF = async (currentAlumno, inscripcion) => {
  fetch('/fonts/Arial.ttf')
    .then(res => res.arrayBuffer())
    .then(res => (fontArial = new pdf.Font(res)));

  fetch('/fonts/Arial-Bold.ttf')
    .then(res => res.arrayBuffer())
    .then(res => (fontArialBold = new pdf.Font(res)));

  let tipoBeca = currentAlumno.inscripcionAlumno.inscripcion.tipo === 'Académica' ? 'academica' : 'deportiva';
  let beca = currentAlumno.inscripcionAlumno.inscripcion.beca?.[`${tipoBeca}`];
  let isSatDone = currentAlumno?.inscripcionAlumno.isSatDone();
  let doc = new pdf.Document({ padding: 0 });

  // PORTADA
  let portada = await getPage('Portada');
  doc.image(portada, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });

  doc
    .cell({ font: fontArialBold, fontSize: 20, x: 0.7 * pdf.cm, y: 11.8 * pdf.cm, width: 8.9 * pdf.cm })
    .text(`${currentAlumno?.informacionAlumno?.nombreCompleto.toUpperCase()}`)
    .br()
    .add(`${currentAlumno?.inscripcionAlumno?.datosColegio?.colegio?.nombre.toUpperCase()}`);

  doc.pageBreak();
  // PAGINA 1
  let page1;

  if (tipoBeca === 'academica') {
    page1 = isSatDone ? await getPage('1-Academico') : await getPage('1-Academico-NoSat');
  } else {
    if (beca.tipoDeporte === 'Equipo') {
      page1 = isSatDone ? await getPage('1-Deportivo-Equipos') : await getPage('1-Deportivo-Equipos-NoSat');
    } else {
      page1 = isSatDone ? await getPage('1-Deportivo-Individual') : await getPage('1-Deportivo-Individual-NoSat');
    }
  }
  doc.image(page1, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });

  let totalNota;

  if (isSatDone) {
    // Notas ingles
    doc.cell(`${currentAlumno.inscripcionAlumno.sat.notas.ingles}`, {
      font: fontArialBold,
      fontSize: 10,
      x: 14 * pdf.cm,
      y: 20.2 * pdf.cm
    });
    doc.cell(`438`, { fontSize: 10, x: 15.5 * pdf.cm, y: 20.2 * pdf.cm });
    doc.cell('83', { fontSize: 10, x: 17.2 * pdf.cm, y: 20.2 * pdf.cm });
    // Notas matematicas
    doc.cell(`${currentAlumno.inscripcionAlumno.sat.notas.matematicas}`, {
      font: fontArialBold,
      fontSize: 10,
      x: 14 * pdf.cm,
      y: 19.3 * pdf.cm
    });
    doc.cell('480', { fontSize: 10, x: 15.5 * pdf.cm, y: 19.3 * pdf.cm });
    doc.cell('101', { fontSize: 10, x: 17.2 * pdf.cm, y: 19.3 * pdf.cm });
    doc.cell('101', { fontSize: 10, x: 17.2 * pdf.cm, y: 19.3 * pdf.cm });
    doc.cell('101', { fontSize: 10, x: 17.2 * pdf.cm, y: 19.3 * pdf.cm });
    // Nota total
    totalNota = currentAlumno.inscripcionAlumno.sat.notas.ingles + currentAlumno.inscripcionAlumno.sat.notas.matematicas;
    doc.cell(`${totalNota}`, {
      font: fontArialBold,
      fontSize: 10,
      x: 14 * pdf.cm,
      y: 18.5 * pdf.cm
    });
    doc.cell('919', { fontSize: 10, x: 15.5 * pdf.cm, y: 18.5 * pdf.cm });
    doc.cell('167', { fontSize: 10, x: 17.2 * pdf.cm, y: 18.5 * pdf.cm });
  }

  // Notas curso anterior
  doc
    .cell({
      font: fontArialBold,
      fontSize: 10,
      x: 13.6 * pdf.cm,
      y: isSatDone ? 13.7 * pdf.cm : 20.9 * pdf.cm,
      width: 5.2 * pdf.cm
    })
    .text(`${currentAlumno.inscripcionAlumno.cursoAnterior.mediaIngles}`, {
      textAlign: 'right'
    });
  doc
    .cell({
      font: fontArialBold,
      fontSize: 10,
      x: 13.6 * pdf.cm,
      y: isSatDone ? 13.05 * pdf.cm : 20.16 * pdf.cm,
      width: 5.2 * pdf.cm
    })
    .text(`${currentAlumno.inscripcionAlumno.cursoAnterior.mediaMatematicas}`, {
      textAlign: 'right'
    });
  doc
    .cell({
      font: fontArialBold,
      fontSize: 10,
      x: 13.6 * pdf.cm,
      y: isSatDone ? 12.4 * pdf.cm : 19.45 * pdf.cm,
      width: 5.2 * pdf.cm
    })
    .text(`${currentAlumno.inscripcionAlumno.cursoAnterior.mediaGlobal}`, {
      textAlign: 'right'
    });

  // Perfil extraescolar

  if (tipoBeca === 'academica') {
    tipoBeca = 'académica';
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 12 * pdf.cm,
        y: isSatDone ? 9.2 * pdf.cm : 16.5 * pdf.cm,
        width: 7 * pdf.cm
      })
      .text(`${tipoBeca ? tipoBeca.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 12 * pdf.cm,
        y: isSatDone ? 8.3 * pdf.cm : 15.75 * pdf.cm,
        width: 7 * pdf.cm
      })
      .text(`${beca.tipoActividad ? beca.tipoActividad.toUpperCase() : '-'}`, {
        textAlign: 'right'
      });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 3.82 * pdf.cm,
        y: isSatDone ? 7.6 * pdf.cm : 14.9 * pdf.cm,
        width: 15.2 * pdf.cm
      })
      .text(`${beca.centroActividad ? beca.centroActividad.toUpperCase() : '-'}`, {
        textAlign: 'right'
      });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 12 * pdf.cm,
        y: isSatDone ? 6.8 * pdf.cm : 14.1 * pdf.cm,
        width: 7 * pdf.cm
      })
      .text(`${beca.dedicationYears ? beca.dedicationYears : '-'}`, {
        textAlign: 'right'
      });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 4.6 * pdf.cm,
        y: isSatDone ? 5.8 * pdf.cm : 13.15 * pdf.cm,
        width: 14.42 * pdf.cm
      })
      .text(`${beca.evaluacionW2A.habilidades.habilidades ? beca.evaluacionW2A.habilidades.habilidades.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 12 * pdf.cm,
        y: isSatDone ? 4.9 * pdf.cm : 12.35 * pdf.cm,
        width: 7 * pdf.cm
      })
      .text(`${beca.voluntariado ? 'SÍ' : 'NO'}`, { textAlign: 'right' });

    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 12 * pdf.cm,
        y: isSatDone ? 3.9 * pdf.cm : 11.4 * pdf.cm,
        width: 7 * pdf.cm
      })
      .text(`${beca.practicas ? 'SÍ' : 'NO'}`, { textAlign: 'right' });
  } else {
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 11.9 * pdf.cm,
        y: isSatDone ? 9.8 * pdf.cm : 16.8 * pdf.cm,
        width: 7.05 * pdf.cm
      })
      .text(`${tipoBeca ? tipoBeca.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 10 * pdf.cm,
        y: isSatDone ? 9.1 * pdf.cm : 16 * pdf.cm,
        width: 9 * pdf.cm
      })
      .text(`${beca.deporte ? beca.deporte.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 4 * pdf.cm,
        y: isSatDone ? 8.32 * pdf.cm : 15.25 * pdf.cm,
        width: 15 * pdf.cm
      })
      .text(`${beca.club ? beca.club.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 9 * pdf.cm,
        y: isSatDone ? 7.45 * pdf.cm : 14.35 * pdf.cm,
        width: 10 * pdf.cm
      })
      .text(`${beca.tipoDeporte === 'Equipo' ? (beca.division ? beca.division.toUpperCase() : '-') : beca.ranking ? beca.ranking.toUpperCase() : '-'}`, {
        textAlign: 'right'
      });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 11.9 * pdf.cm,
        y: isSatDone ? 6.52 * pdf.cm : 13.45 * pdf.cm,
        width: 7.05 * pdf.cm
      })
      .text(`${beca.categoria ? beca.categoria.toUpperCase() : '-'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 11.9 * pdf.cm,
        y: isSatDone ? 5.6 * pdf.cm : 12.55 * pdf.cm,
        width: 7.05 * pdf.cm
      })
      .text(`${beca.voluntariado ? 'SÍ' : 'NO'}`, { textAlign: 'right' });
    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 11.9 * pdf.cm,
        y: isSatDone ? 4.62 * pdf.cm : 11.55 * pdf.cm,
        width: 7.05 * pdf.cm
      })
      .text(`${beca.practicas ? 'SÍ' : 'NO'}`, { textAlign: 'right' });

    doc
      .cell({
        font: fontArialBold,
        fontSize: 10,
        x: 11.9 * pdf.cm,
        y: isSatDone ? 3.6 * pdf.cm : 10.55 * pdf.cm,
        width: 7.05 * pdf.cm
      })
      .text(`${beca.federado === 'Si' ? 'SÍ' : 'NO'}`, { textAlign: 'right' });
  }

  doc.pageBreak();
  // PAGINA 2
  const page2 = await getPage('2');
  doc.image(page2, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });

  doc.cell(`${currentAlumno.inscripcionAlumno?.tipoCarrera}`, {
    x: 7 * pdf.cm,
    y: 25.1 * pdf.cm,
    width: 13 * pdf.cm,
    fontSize: 13,
    font: fontArialBold,
    textAlign: 'right'
  });

  let valoresCuestionario = currentAlumno.inscripcionAlumno.getValoresCuestionario();
  let areas = {};
  // Kinestesica
  areas[`Kinestésica`] = printHabilidadGeneral(doc, valoresCuestionario[`HG-K`], 5, 24, 19.76);
  areas[`Kinestésica`] = areas[`Kinestésica`] + printInteresGeneral(doc, valoresCuestionario[`IG-K`], 5, 24, 19.1);
  // Musical
  areas.Musical = printHabilidadGeneral(doc, valoresCuestionario[`HG-M`], 5, 24, 17.28);
  areas.Musical = areas.Musical + printInteresGeneral(doc, valoresCuestionario[`IG-M`], 5, 24, 16.6);
  // Naturalista
  areas.Naturalista = printHabilidadGeneral(doc, valoresCuestionario[`HG-N`], 5, 24, 14.88);
  areas.Naturalista = areas.Naturalista + printInteresGeneral(doc, valoresCuestionario[`IG-N`], 5, 24, 14.18);
  // Intrapersonal
  areas.Intrapersonal = printHabilidadGeneral(doc, valoresCuestionario[`HG-Ia`], 5, 24, 12.48);
  areas.Intrapersonal = areas.Intrapersonal + printInteresGeneral(doc, valoresCuestionario[`IG-Ia`], 5, 24, 11.78);
  // Interpersonal
  areas.Interpersonal = printHabilidadGeneral(doc, valoresCuestionario[`HG-Ie`], 5, 24, 10.12);
  areas.Interpersonal = areas.Interpersonal + printInteresGeneral(doc, valoresCuestionario[`IG-Ie`], 5, 24, 9.4);
  // Visual Espacial
  areas[`Visual-Espacial`] = printHabilidadGeneral(doc, valoresCuestionario[`HG-VE`], 5, 24, 7.65);
  areas[`Visual-Espacial`] = areas[`Visual-Espacial`] + printInteresGeneral(doc, valoresCuestionario[`IG-VE`], 5, 24, 7);
  // Logico Matematica
  areas[`Lógico-Matemática`] = printHabilidadGeneral(doc, valoresCuestionario[`HG-LM`], 5, 24, 5.16);
  areas[`Lógico-Matemática`] = areas['Lógico-Matemática'] + printInteresGeneral(doc, valoresCuestionario[`IG-LM`], 5, 24, 4.5);
  // Lingüistica
  areas[`Lingüistica`] = printHabilidadGeneral(doc, valoresCuestionario[`HG-L`], 5, 24, 2.73);
  areas[`Lingüistica`] = areas[`Lingüistica`] + printInteresGeneral(doc, valoresCuestionario[`IG-L`], 5, 24, 2.08);
  areas = Object.entries(areas)
    .sort(([key1, n1], [key2, n2]) => n1 - n2)
    .reverse();

  doc.pageBreak();

  // PAGINA 3
  const page3 = await getPage('3');
  doc.image(page3, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });

  let ramas = {};
  // Juridica
  ramas[`Jurídica`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-CS-J`], 6, 40, 16.7);
  ramas[`Jurídica`] = ramas[`Jurídica`] + printInteresAcademica(doc, valoresCuestionario[`IA-CS-J`], 8, 44, 16.47);
  // Empresa
  ramas.Empresa = printHabilidadAcademica(doc, valoresCuestionario[`HA-CS-E`], 3, 24, 15.95);
  ramas.Empresa = ramas.Empresa + printInteresAcademica(doc, valoresCuestionario[`IA-CS-E`], 4, 28, 15.72);
  // Atencion Persona
  ramas[`Atención a personas`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-CS-AP`], 6, 36, 15.2);
  ramas[`Atención a personas`] = ramas['Atención a personas'] + printInteresAcademica(doc, valoresCuestionario[`IA-CS-AP`], 10, 52, 14.97);
  // Comunicacion
  ramas[`Comunicación`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-CS-C`], 3, 20, 14.37);
  ramas[`Comunicación`] = ramas.Comunicación + printInteresAcademica(doc, valoresCuestionario[`IA-CS-C`], 5, 24, 14.14);
  // Escenicas y Musica
  ramas[`Escénica/Música`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-A-EM`], 4, 28, 13);
  ramas[`Escénica/Música`] = ramas['Escénica/Música'] + printInteresAcademica(doc, valoresCuestionario[`IA-A-EM`], 8, 44, 12.77);
  // Plasticas
  ramas[`Plásticas`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-A-P`], 3, 24, 12.17);
  ramas[`Plásticas`] = ramas.Plásticas + printInteresAcademica(doc, valoresCuestionario[`IA-A-P`], 6, 36, 11.94);
  // Digitales
  ramas.Digitales = printHabilidadAcademica(doc, valoresCuestionario[`HA-A-D`], 3, 28, 11.4);
  ramas.Digitales = ramas.Digitales + printInteresAcademica(doc, valoresCuestionario[`IA-A-D`], 6, 40, 11.17);
  // Filologias e Idiomas
  ramas[`Filología/Idiomas`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-H-FI`], 3, 32, 10);
  ramas[`Filología/Idiomas`] = ramas['Filología/Idiomas'] + printInteresAcademica(doc, valoresCuestionario[`IA-H-FI`], 5, 40, 9.77);
  // Historia
  ramas.Historia = printHabilidadAcademica(doc, valoresCuestionario[`HA-H-H`], 3, 32, 9.2);
  ramas.Historia = ramas.Historia + printInteresAcademica(doc, valoresCuestionario[`IA-H-H`], 5, 40, 8.97);
  // Salud
  ramas.Salud = printHabilidadAcademica(doc, valoresCuestionario[`HA-C-S`], 3, 24, 7.6);
  ramas.Salud = ramas.Salud + printInteresAcademica(doc, valoresCuestionario[`IA-C-S`], 14, 68, 7.37);
  // Aplicadas
  ramas[`Ciencias Aplicadas`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-C-A`], 3, 24, 6.87);
  ramas[`Ciencias Aplicadas`] = ramas['Ciencias Aplicadas'] + printInteresAcademica(doc, valoresCuestionario[`IA-C-A`], 9, 48, 6.64);
  // Especificas
  ramas[`Ciencias Específicas`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-C-E`], 3, 28, 6.04);
  ramas[`Ciencias Específicas`] = ramas['Ciencias Específicas'] + printInteresAcademica(doc, valoresCuestionario[`IA-C-E`], 9, 56, 5.81);
  // Ingenieria y Arquitectura
  ramas[`Ingeniería/Arquitectura`] = printHabilidadAcademica(doc, valoresCuestionario[`HA-IA`], 7, 52, 5.21);
  ramas[`Ingeniería/Arquitectura`] = ramas['Ingeniería/Arquitectura'] + printInteresAcademica(doc, valoresCuestionario[`IA-IA`], 10, 60, 4.98);
  // Deporte
  ramas.Deporte = printHabilidadAcademica(doc, valoresCuestionario[`HA-D`], 3, 20, 3.5);
  ramas.Deporte = ramas.Deporte + printInteresAcademica(doc, valoresCuestionario[`IA-D`], 7, 40, 3.27);

  ramas = Object.entries(ramas)
    .sort((rama1, rama2) => rama1[1] - rama2[1])
    .reverse();
  doc.pageBreak();

  // PAGINA 4
  const page4 = await getPage('4');
  doc.image(page4, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });

  // Lo que valoras en tu trabajo
  printValoresTrabajo(doc, valoresCuestionario);
  // Tu perfil profesional
  printPerfilProfesional(doc, valoresCuestionario);

  doc.pageBreak();

  // PAGINA 5
  const page5 = await getPage('5Rama');
  doc.image(page5, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });
  // Tus resultados

  doc.cell(`${currentAlumno?.inscripcionAlumno?.preferenciaPaises[0].text.toUpperCase()}`, {
    x: 5.9 * pdf.cm,
    y: 22.3 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 93
  });
  doc.cell(`${currentAlumno?.inscripcionAlumno?.preferenciaPaises[1].text.toUpperCase()}`, {
    x: 5.9 * pdf.cm,
    y: 21.8 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 93
  });
  doc.cell(`${ramasGenerales[currentAlumno?.inscripcionAlumno?.tipoCarrera].toUpperCase()}`, {
    x: 5 * pdf.cm,
    y: 20.88 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 4.25 * pdf.cm
  });
  doc.cell(`${currentAlumno?.inscripcionAlumno?.ingles?.nivel.toUpperCase()}`, {
    x: 6.05 * pdf.cm,
    y: 20 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 90
  });
  doc.cell(`${currentAlumno?.inscripcionAlumno?.cursoAnterior?.mediaGlobal}`, {
    x: 6.05 * pdf.cm,
    y: 19.2 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 90
  });
  doc.cell(`${totalNota ? totalNota : '-'}`, {
    x: 6.05 * pdf.cm,
    y: 18.3 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 90
  });
  doc.cell(`${beca?.nombreActividad ? beca?.nombreActividad.toUpperCase() : beca?.deporte.toUpperCase()}`, {
    x: 3.78 * pdf.cm,
    y: 17.3 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 5.5 * pdf.cm
  });
  doc.cell(`${beca?.evaluacionW2A?.habilidades?.habilidades ? beca?.evaluacionW2A?.habilidades?.habilidades.toUpperCase() : 'NO RESPONDIDO'}`, {
    x: 6.05 * pdf.cm,
    y: 16.4 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 90
  });
  // Tu perfil
  doc.cell(`${areas[0][0].toUpperCase()}`, {
    x: 12.05 * pdf.cm,
    y: 21.7 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 7 * pdf.cm
  });
  doc.cell(`${areas[1][0].toUpperCase()}`, {
    x: 12.05 * pdf.cm,
    y: 21.2 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 7 * pdf.cm
  });
  doc.cell(`${ramas[0][0].toUpperCase()}`, {
    x: 12.05 * pdf.cm,
    y: 19.2 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 7 * pdf.cm
  });
  doc.cell(`${ramas[1][0].toUpperCase()}`, {
    x: 12.05 * pdf.cm,
    y: 18.7 * pdf.cm,
    font: fontArialBold,
    fontSize: 10,
    textAlign: 'right',
    width: 7 * pdf.cm
  });

  // Ejemplos de carreras por area
  doc
    .cell({
      x: 4.45 * pdf.cm,
      y: 8.2 * pdf.cm,
      font: fontArial,
      fontSize: 10,
      width: 4.5 * pdf.cm
    })
    .text(`${ramas[0][0]}`, { textAlign: 'center' });
  let profesiones1 = profesionesRama[`${ramas[0][0]}`];
  let profesiones2 = profesionesRama[`${ramas[1][0]}`];

  doc.cell(`${profesiones1[0]}`, {
    x: 10.6 * pdf.cm,
    y: 9.54 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones1[1]}`, {
    x: 10.6 * pdf.cm,
    y: 8.89 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones1[2]}`, {
    x: 10.6 * pdf.cm,
    y: 8.29 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones1[3]}`, {
    x: 10.6 * pdf.cm,
    y: 7.6 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones1[4]}`, {
    x: 10.6 * pdf.cm,
    y: 6.92 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });

  doc
    .cell({
      x: 4.5 * pdf.cm,
      y: 3.2 * pdf.cm,
      font: fontArial,
      fontSize: 10,
      width: 4.5 * pdf.cm
    })
    .text(`${ramas[1][0]}`, { textAlign: 'center' });

  doc.cell(`${profesiones2[0]}`, {
    x: 10.6 * pdf.cm,
    y: 4.5 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones2[1]}`, {
    x: 10.6 * pdf.cm,
    y: 3.9 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones2[2]}`, {
    x: 10.6 * pdf.cm,
    y: 3.2 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones2[3]}`, {
    x: 10.6 * pdf.cm,
    y: 2.55 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });
  doc.cell(`${profesiones2[4]}`, {
    x: 10.6 * pdf.cm,
    y: 1.9 * pdf.cm,
    font: fontArial,
    fontSize: 7.5
  });

  doc.pageBreak();

  // PAGINA 6
  let page6, page7, page8;
  let pais1 = currentAlumno?.inscripcionAlumno?.preferenciaPaises[0].text.replace(/,/g, '');
  let pais2 = currentAlumno?.inscripcionAlumno?.preferenciaPaises[1].text.replace(/,/g, '');

  let categoria = currentAlumno?.inscripcionAlumno?.inscripcion?.beca?.deportiva?.evaluacionW2A?.categoria;
  //console.log(`W2A Categoria Deportiva -> ${categoria}`);

  if (currentAlumno.inscripcionAlumno.inscripcion.tipo === 'Deportiva') {
    if (!categoria) return null;
    // Primera Recomendacion EEUU
    page6 = await getPage('6');
    doc.image(page6, {
      align: 'center',
      width: 21 * pdf.cm,
      height: 29.7 * pdf.cm
    });
    await writeRecomendacionUniversitaria(doc, 'EE.UU', currentAlumno.inscripcionAlumno, currentAlumno.id, inscripcion, categoria);
    doc.pageBreak();

    // Recomendacion Universidad Europea Deporte
    page7 = await getPage('Europea-Deporte');
    doc.image(page7, {
      align: 'center',
      width: 21 * pdf.cm,
      height: 29.7 * pdf.cm
    });

    doc.cell('7', {
      font: fontArialBold,
      fontSize: 9,
      x: 18.6 * pdf.cm,
      y: 28.3 * pdf.cm
    });

    doc.pageBreak();

    // Guias
    await printGuias(doc, '8', '9');
  } else {
    if (pais1 === 'España') {
      // Recomendacion Universidad Europea
      page6 = await getPage(paginaEuropea[currentAlumno?.inscripcionAlumno?.tipoCarrera]);
      doc.image(page6, {
        align: 'center',
        width: 21 * pdf.cm,
        height: 29.7 * pdf.cm
      });
      doc.cell('6', {
        font: fontArialBold,
        fontSize: 9,
        x: 18.6 * pdf.cm,
        y: 28.3 * pdf.cm
      });

      doc.pageBreak();

      // Recomendacion 2 Pais
      page7 = await getPage('7');
      doc.image(page7, {
        align: 'center',
        width: 21 * pdf.cm,
        height: 29.7 * pdf.cm
      });

      await writeRecomendacionUniversitaria(doc, pais2, currentAlumno?.inscripcionAlumno, currentAlumno.id, inscripcion, categoria);

      doc.pageBreak();

      // Guias
      await printGuias(doc, '8', '9');
    } else {
      // Recomendacion 1 Pais
      page6 = await getPage('6');
      doc.image(page6, {
        align: 'center',
        width: 21 * pdf.cm,
        height: 29.7 * pdf.cm
      });

      await writeRecomendacionUniversitaria(doc, pais1, currentAlumno?.inscripcionAlumno, currentAlumno.id, inscripcion, categoria);

      doc.pageBreak();

      if (pais2 === 'España') {
        page7 = await getPage(paginaEuropea[currentAlumno?.inscripcionAlumno?.tipoCarrera]);
        doc.image(page7, {
          align: 'center',
          width: 21 * pdf.cm,
          height: 29.7 * pdf.cm
        });
        doc.cell('7', {
          font: fontArialBold,
          fontSize: 9,
          x: 18.6 * pdf.cm,
          y: 28.3 * pdf.cm
        });
        doc.pageBreak();

        await printGuias(doc, '8', '9');
      } else {
        // Recomendacion 2 Pais
        page7 = await getPage('7');
        doc.image(page7, {
          align: 'center',
          width: 21 * pdf.cm,
          height: 29.7 * pdf.cm
        });

        await writeRecomendacionUniversitaria(doc, pais2, currentAlumno?.inscripcionAlumno, currentAlumno.id, inscripcion, categoria);
        doc.pageBreak();

        // Recomendacion Universidad Europea
        page8 = await getPage(paginaEuropea[currentAlumno?.inscripcionAlumno?.tipoCarrera]);
        doc.image(page8, {
          align: 'center',
          width: 21 * pdf.cm,
          height: 29.7 * pdf.cm
        });
        doc.cell('8', {
          font: fontArialBold,
          fontSize: 9,
          x: 18.6 * pdf.cm,
          y: 28.3 * pdf.cm
        });
        doc.pageBreak();

        await printGuias(doc, '9', '10');
      }
    }
  }

  return doc.asBuffer().then(data => {
    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  });
};

const getPage = async pagina => {
  const res = await fetch(`/pdfs/${pagina}.pdf`);
  const buffer = await res.arrayBuffer();
  return new pdf.Image(buffer);
};

const printHabilidadGeneral = (doc, valorHabilidad, minValor, maxValor, positionY) => {
  let valoresAncho = {
    1: 33,
    2: 67,
    3: 101,
    4: 135,
    5: 169,
    6: 203,
    7: 237,
    8: 271,
    9: 305,
    10: 339
  };
  let anchoFila = calculaValor(valorHabilidad, minValor, maxValor);
  const cellHabilidad = doc.cell({ x: 7.61 * pdf.cm, y: positionY * pdf.cm });
  cellHabilidad
    .table({
      widths: [valoresAncho[anchoFila], 10],
      borderHorizontalWidth: 5.1,
      borderColor: '#41B9E7'
    })
    .row()
    .cell();
  return anchoFila;
};

const printInteresGeneral = (doc, valorInteres, minValor, maxValor, positionY) => {
  let valoresAncho = {
    1: 33,
    2: 67,
    3: 101,
    4: 135,
    5: 169,
    6: 203,
    7: 237,
    8: 271,
    9: 305,
    10: 339
  };
  let anchoFila = calculaValor(valorInteres, minValor, maxValor);
  const cellKinestesicaInteres = doc.cell({
    x: 7.61 * pdf.cm,
    y: positionY * pdf.cm
  });
  cellKinestesicaInteres
    .table({
      widths: [valoresAncho[anchoFila], 10],
      borderHorizontalWidth: 5.1,
      borderColor: '#0555FA'
    })
    .row()
    .cell();
  return anchoFila;
};

const printHabilidadAcademica = (doc, valorHabilidad, minValor, maxValor, positionY) => {
  let valoresAncho = {
    1: 43,
    2: 77,
    3: 111,
    4: 145,
    5: 179,
    6: 213,
    7: 247,
    8: 281,
    9: 315,
    10: 349
  };
  let anchoFila = calculaValor(valorHabilidad, minValor, maxValor);
  const cellHabilidad = doc.cell({ x: 7.09 * pdf.cm, y: positionY * pdf.cm });
  cellHabilidad
    .table({
      widths: [valoresAncho[anchoFila], 10],
      borderHorizontalWidth: 2.4,
      borderColor: '#1CAF8D'
    })
    .row()
    .cell();
  return anchoFila;
};

const printInteresAcademica = (doc, valorInteres, minValor, maxValor, positionY) => {
  let valoresAncho = {
    1: 43,
    2: 77,
    3: 111,
    4: 145,
    5: 179,
    6: 213,
    7: 247,
    8: 281,
    9: 315,
    10: 349
  };
  let anchoFila = calculaValor(valorInteres, minValor, maxValor);
  const cellKinestesicaInteres = doc.cell({
    x: 7.09 * pdf.cm,
    y: positionY * pdf.cm
  });
  cellKinestesicaInteres
    .table({
      widths: [valoresAncho[anchoFila], 10],
      borderHorizontalWidth: 2.4,
      borderColor: '#41953B'
    })
    .row()
    .cell();
  return anchoFila;
};

const printValoresTrabajo = (doc, valoresCuestionario) => {
  let valoresAncho = {
    1: 30,
    2: 61,
    3: 92,
    4: 123,
    5: 154,
    6: 185,
    7: 216,
    8: 247,
    9: 278,
    10: 309
  };

  let valoresTrabajo = {};
  valoresTrabajo[`Flexibilidad horaria`] = calculaValor(valoresCuestionario[`VT-TL`], 3, 19);
  valoresTrabajo[`Independencia`] = calculaValor(valoresCuestionario[`VT-I`], 3, 19);
  valoresTrabajo[`Trabajo supervisado`] = calculaValor(valoresCuestionario[`VT-TS`], 3, 19);
  valoresTrabajo[`Prestigio`] = calculaValor(valoresCuestionario[`VT-P`], 3, 19);
  valoresTrabajo[`Altruismo`] = calculaValor(valoresCuestionario[`VT-A`], 3, 19);
  valoresTrabajo[`Creatividad`] = calculaValor(valoresCuestionario[`VT-C`], 3, 19);
  valoresTrabajo[`Relaciones sociales`] = calculaValor(valoresCuestionario[`VT-RS`], 3, 19);
  valoresTrabajo[`Asumir responsabilidad`] = calculaValor(valoresCuestionario[`VT-Are`], 3, 19);
  valoresTrabajo[`Seguridad en la ocupación`] = calculaValor(valoresCuestionario[`VT-SO`], 3, 19);
  valoresTrabajo[`Buen sueldo`] = calculaValor(valoresCuestionario[`VT-BS`], 3, 19);
  valoresTrabajo[`Actividad rutinaria`] = calculaValor(valoresCuestionario[`VT-Aru`], 3, 19);

  valoresTrabajo = Object.entries(valoresTrabajo)
    .sort((n1, n2) => n1[1] - n2[1])
    .reverse();

  doc
    .cell({
      x: 3.28 * pdf.cm,
      y: 21.46 * pdf.cm,
      fontSize: 9,
      font: fontArialBold,
      width: 4.55 * pdf.cm
    })
    .text(`${valoresTrabajo[0][0]}`, { textAlign: 'center' });
  doc
    .cell({
      x: 8.98 * pdf.cm,
      y: valoresTrabajo[1][0].length > 20 ? 21.65 * pdf.cm : 21.46 * pdf.cm,
      fontSize: 9,
      font: fontArialBold,
      width: 3.67 * pdf.cm
    })
    .text(`${valoresTrabajo[1][0]}`, { textAlign: 'center' });
  doc
    .cell({
      x: 14.3 * pdf.cm,
      y: valoresTrabajo[2][0].length > 15 ? 21.7 * pdf.cm : 21.52 * pdf.cm,
      fontSize: 9,
      font: fontArialBold,
      width: 2.6 * pdf.cm
    })
    .text(`${valoresTrabajo[2][0]}`, { textAlign: 'center' });

  doc.cell(`${valoresTrabajo[3][0]}`, {
    x: 3.5 * pdf.cm,
    y: 17.62 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell1 = doc.cell({ x: 7.26 * pdf.cm, y: 17.48 * pdf.cm });
  cell1
    .table({
      widths: [valoresAncho[valoresTrabajo[3][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[4][0]}`, {
    x: 3.5 * pdf.cm,
    y: 16.98 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell2 = doc.cell({ x: 7.26 * pdf.cm, y: 16.88 * pdf.cm });
  cell2
    .table({
      widths: [valoresAncho[valoresTrabajo[4][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[5][0]}`, {
    x: 3.5 * pdf.cm,
    y: 16.34 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell3 = doc.cell({ x: 7.26 * pdf.cm, y: 16.24 * pdf.cm });
  cell3
    .table({
      widths: [valoresAncho[valoresTrabajo[5][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[6][0]}`, {
    x: 3.5 * pdf.cm,
    y: 15.73 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell4 = doc.cell({ x: 7.26 * pdf.cm, y: 15.64 * pdf.cm });
  cell4
    .table({
      widths: [valoresAncho[valoresTrabajo[6][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[7][0]}`, {
    x: 3.5 * pdf.cm,
    y: 15.14 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell5 = doc.cell({ x: 7.26 * pdf.cm, y: 15.04 * pdf.cm });
  cell5
    .table({
      widths: [valoresAncho[valoresTrabajo[7][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[8][0]}`, {
    x: 3.5 * pdf.cm,
    y: 14.52 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell6 = doc.cell({ x: 7.26 * pdf.cm, y: 14.44 * pdf.cm });
  cell6
    .table({
      widths: [valoresAncho[valoresTrabajo[8][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[9][0]}`, {
    x: 3.5 * pdf.cm,
    y: 13.88 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell7 = doc.cell({ x: 7.26 * pdf.cm, y: 13.74 * pdf.cm });
  cell7
    .table({
      widths: [valoresAncho[valoresTrabajo[9][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();

  doc.cell(`${valoresTrabajo[10][0]}`, {
    x: 3.5 * pdf.cm,
    y: 13.26 * pdf.cm,
    font: fontArial,
    fontSize: 8
  });
  const cell8 = doc.cell({ x: 7.26 * pdf.cm, y: 13.14 * pdf.cm });
  cell8
    .table({
      widths: [valoresAncho[valoresTrabajo[10][1]], 10],
      borderHorizontalWidth: 2.5,
      borderColor: '#693092'
    })
    .row()
    .cell();
};

const printPerfilProfesional = (doc, valoresCuestionario) => {
  let valoresAnchoPositivo = {
    1: 12,
    2: 26,
    3: 40,
    4: 54,
    5: 68,
    6: 82,
    7: 96,
    8: 110,
    9: 124,
    10: 138
  };

  let valoresAnchoNegativo = {
    1: -14,
    2: -29,
    3: -44,
    4: -59,
    5: -74,
    6: -89,
    7: -104,
    8: -119,
    9: -134,
    10: -149
  };

  let social = calculaValor(valoresCuestionario[`Per-SA-S`], 8, 43);
  let autonomo = calculaValor(valoresCuestionario[`Per-SA-A`], 3, 19);

  const cellSocial = doc.cell({ x: 10.14 * pdf.cm, y: 7.3 * pdf.cm });
  cellSocial
    .table({
      widths: [valoresAnchoNegativo[social], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#0555FA'
    })
    .row()
    .cell();

  const cellAutonomo = doc.cell({ x: 10.15 * pdf.cm, y: 7.3 * pdf.cm });

  cellAutonomo
    .table({
      widths: [valoresAnchoPositivo[autonomo], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#F2359D'
    })
    .row()
    .cell();

  let prestigioso = calculaValor(valoresCuestionario[`Per-PRp-P`], 6, 38);
  let realizacionPersonal = calculaValor(valoresCuestionario[`Per-PRp-Rp`], 9, 57);

  const cellPrestigio = doc.cell({ x: 10.14 * pdf.cm, y: 5.42 * pdf.cm });
  cellPrestigio
    .table({
      widths: [valoresAnchoNegativo[prestigioso], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#0555FA'
    })
    .row()
    .cell();

  const cellRealizacion = doc.cell({ x: 10.15 * pdf.cm, y: 5.42 * pdf.cm });
  cellRealizacion
    .table({
      widths: [valoresAnchoPositivo[realizacionPersonal], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#F2359D'
    })
    .row()
    .cell();

  let creativo = calculaValor(valoresCuestionario[`Per-CR-C`], 3, 19);
  let rutinario = calculaValor(valoresCuestionario[`Per-CR-R`], 6, 38);

  const cellCreatividad = doc.cell({ x: 10.14 * pdf.cm, y: 3.38 * pdf.cm });
  cellCreatividad
    .table({
      widths: [valoresAnchoNegativo[creativo], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#0555FA'
    })
    .row()
    .cell();

  const cellRutina = doc.cell({ x: 10.15 * pdf.cm, y: 3.38 * pdf.cm });
  cellRutina
    .table({
      widths: [valoresAnchoPositivo[rutinario], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#F2359D'
    })
    .row()
    .cell();

  let emprendedor = calculaValor(valoresCuestionario[`PER-EE-Em`], 9, 57);
  let estabilidad = calculaValor(valoresCuestionario[`PER-EE-Es`], 6, 38);

  const cellEmprendedor = doc.cell({ x: 10.14 * pdf.cm, y: 1.52 * pdf.cm });
  cellEmprendedor
    .table({
      widths: [valoresAnchoNegativo[emprendedor], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#0555FA'
    })
    .row()
    .cell();

  const cellEstabilidad = doc.cell({ x: 10.15 * pdf.cm, y: 1.52 * pdf.cm });
  cellEstabilidad
    .table({
      widths: [valoresAnchoPositivo[estabilidad], 10],
      borderHorizontalWidth: 3.5,
      borderColor: '#F2359D'
    })
    .row()
    .cell();
};

const calculaValor = (valor, minValor, maxValor) => {
  let resultado = Math.round((10 * valor) / maxValor - minValor, 1);
  return resultado <= 0 ? 1 : resultado >= 10 ? 10 : resultado;
};

const writeRecomendacionUniversitaria = async (doc, pais, inscripcionAlumno, idAlumno, inscripcion, categoria) => {
  let paisesInforme;
  let university1, university2, university3, university4, notaAcceso1, notaAcceso2;
  let rama = getRama(pais, inscripcionAlumno?.tipoCarrera);
  let tipoBeca = inscripcionAlumno.inscripcion?.tipo;
  let matematicasNotaSat = inscripcionAlumno?.sat?.notas?.matematicas ? inscripcionAlumno?.sat?.notas?.matematicas : 250;
  let inglesNotaSat = inscripcionAlumno?.sat?.notas?.ingles ? inscripcionAlumno?.sat?.notas?.ingles : 250;
  let totalNota = matematicasNotaSat + inglesNotaSat;
  let potencialMejora = getNotaPotencial(inscripcionAlumno.cursoActual.curso, totalNota, inscripcionAlumno.cursoAnterior, matematicasNotaSat, inglesNotaSat);
  let notaMedia = inscripcionAlumno.cursoAnterior.mediaGlobal > 10 ? 10 : inscripcionAlumno.cursoAnterior.mediaGlobal;
  //console.log("writeRecomendacionUniversitaria", pais, tipoBeca)

  await db.child(`Inscripciones/Alumnos/${inscripcion}/${idAlumno}/informe`).once('value', snapshot => {
    paisesInforme = snapshot.val();
  });

  if (pais === 'EE.UU' || tipoBeca === 'Deportiva') {
    totalNota = inscripcionAlumno.isSatDone() ? totalNota : 500;
    if (tipoBeca === 'Deportiva') {
      pais = 'EE.UU';
      // Si ya tiene universidades asignadas en BBDD
      if (paisesInforme?.[`EE-UU`]) {
        let universidades = paisesInforme?.[`EE-UU`]?.universidades;
        university1 = universitiesAmerica[universidades?.sinPotencial?.nombres[0]];
        university2 = universitiesAmerica[universidades?.sinPotencial?.nombres[1]];
        notaAcceso1 = universidades?.sinPotencial?.rango;
        // Tras potencial
        totalNota = totalNota + potencialMejora;
        university3 = universitiesAmerica[universidades?.conPotencial?.nombres[0]];
        university4 = universitiesAmerica[universidades?.conPotencial?.nombres[1]];
        notaAcceso2 = universidades.conPotencial.rango;
      } else {
        rama = getRama(pais, inscripcionAlumno.tipoCarrera);
        const { universityName1, universityName2, rango: rango1 } = getUniversidadDeportiva(inscripcionAlumno, rama, totalNota, categoria);
        university1 = universitiesAmerica[universityName1];
        university2 = universitiesAmerica[universityName2];
        notaAcceso1 = rango1;
        // Tras potencial
        totalNota = totalNota + potencialMejora;
        const {
          universityName1: universityName3,
          universityName2: universityName4,
          rango: rango2
        } = getUniversidadDeportiva(inscripcionAlumno, rama, totalNota, categoria);
        university3 = universitiesAmerica[universityName3];
        university4 = universitiesAmerica[universityName4];
        notaAcceso2 = rango2;
      }
    } else {
      if (!!paisesInforme?.[`EE-UU`]) {
        //console.log("kknn", universitiesAmerica)
        let universidades = paisesInforme?.[`EE-UU`]?.universidades;
        //console.log("universidades", universidades)

        university1 = universitiesAmerica[universidades?.sinPotencial?.nombres[0]];
        university2 = universitiesAmerica[universidades?.sinPotencial?.nombres[1]];
        notaAcceso1 = universidades.sinPotencial.rango;
        // Tras potencial
        totalNota = totalNota + potencialMejora;
        university3 = universitiesAmerica[universidades?.conPotencial?.nombres[0]];
        university4 = universitiesAmerica[universidades?.conPotencial?.nombres[1]];
        notaAcceso2 = universidades.conPotencial.rango;
        //console.log("university1", university1)
      } else {
        //console.log("3333")
        const { universityName1: universityName1, universityName2: universityName2, rango: rango1 } = getUniversidadAcademicaEEUU(rama, totalNota);
        university1 = universitiesAmerica[universityName1];
        university2 = universitiesAmerica[universityName2];
        notaAcceso1 = rango1;
        // Tras potencial
        totalNota = totalNota + potencialMejora;
        const { universityName1: universityName3, universityName2: universityName4, rango: rango2 } = getUniversidadAcademicaEEUU(rama, totalNota);
        university3 = universitiesAmerica[universityName3];
        university4 = universitiesAmerica[universityName4];
        notaAcceso2 = rango2;
      }
    }
  } else {
    if (paisesInforme?.[`${pais}`]) {
      let universidades = paisesInforme?.[`${pais}`]?.universidades;
      university1 = universitiesEuropa[pais]?.[universidades?.sinPotencial?.nombres[0]];
      university2 = universitiesEuropa[pais]?.[universidades?.sinPotencial?.nombres[1]];
      notaAcceso1 = universidades.sinPotencial.rango;
      // Tras potencial
      potencialMejora = ((10 * potencialMejora) / 1600).toFixed(2);
      notaMedia = parseFloat(notaMedia) + parseFloat(potencialMejora) >= 10 ? 10 : parseFloat(notaMedia) + parseFloat(potencialMejora);
      university3 = universitiesEuropa[pais]?.[universidades?.conPotencial?.nombres[0]];
      university4 = universitiesEuropa[pais]?.[universidades?.conPotencial?.nombres[1]];
      notaAcceso2 = universidades.conPotencial.rango;
    } else {
      console.log('entramoooos!!');
      if (rama === 'Juridical') {
        rama = 'Business';
      }
      const { universityName1, universityName2, rango: rango1 } = getUniversidadAcademicaEuropa(pais, notaMedia, rama);

      // if (rama === 'Juridical') {
      //   university1 = universitiesEuropa.España['Universidad Europea'];
      //   university2 = universitiesEuropa.España['Universidad Carlos III'];
      //   notaAcceso1 = rango1;
      // } else {
      university1 = universitiesEuropa[pais]?.[universityName1];
      university2 = universitiesEuropa[pais]?.[universityName2];
      notaAcceso1 = rango1;
      // }

      // Tras potencial
      //console.log(potencialMejora)
      potencialMejora = ((10 * potencialMejora) / 1600).toFixed(2);
      //console.log(potencialMejora)

      notaMedia = parseFloat(notaMedia) + parseFloat(potencialMejora) >= 10 ? 10 : parseFloat(notaMedia) + parseFloat(potencialMejora);
      if (rama === 'Juridical') {
        rama = 'Business';
      }
      const { universityName1: universityName3, universityName2: universityName4, rango: rango2 } = getUniversidadAcademicaEuropa(pais, notaMedia, rama);
      university3 = universitiesEuropa[pais]?.[universityName3];
      university4 = universitiesEuropa[pais]?.[universityName4];
      notaAcceso2 = rango2;
    }
  }

  //console.log(university1)
  //console.log(university2)
  //console.log(university3)
  //console.log(university4)
  //console.log(university4)
  //console.log("paisesInforme", paisesInforme)
  //console.log("universitiesEuropa", universitiesEuropa)
  let paisMultipath = pais === 'EE.UU' ? 'EE-UU' : pais;
  if (!paisesInforme?.[`${paisMultipath}`]) {
    let multipath = {};
    multipath[`Inscripciones/Alumnos/${inscripcion}/${idAlumno}/informe/${paisMultipath}/universidades/sinPotencial/`] = {
      nombres: [university1?.Name, university2?.Name],
      rango: notaAcceso1
    };

    multipath[`Inscripciones/Alumnos/${inscripcion}/${idAlumno}/informe/${paisMultipath}/universidades/conPotencial/`] = {
      nombres: [university3?.Name, university4?.Name],
      rango: notaAcceso2
    };
    console.log('multipath', multipath);
    await db
      .update(multipath)
      .then(() => console.log('Universidades Guardadas'))
      .catch(err => console.log(err));
  }

  doc.cell(`RECOMENDACIÓN UNIVERSITARIA - ${pais.toUpperCase()}`, {
    x: 4.55 * pdf.cm,
    y: 26.8 * pdf.cm,
    fontSize: 18.5,
    font: fontArialBold
  });

  // Universidad 1
  let logo1 = university1.Logo.replace(/\s/g, '-');
  await printLogo(doc, logo1, 1.5, 23);
  doc.cell({ x: 3.8 * pdf.cm, y: 23.05 * pdf.cm, width: 6.5 * pdf.cm }).text(`${university1.Name.toUpperCase()}`, {
    font: fontArialBold,
    fontSize: 11,
    color: '#41B9E7'
  });

  doc.cell(`${notaAcceso1}`, {
    x: 7.77 * pdf.cm,
    y: 20.92 * pdf.cm,
    font: fontArial,
    fontSize: 12
  });

  const cellGuiones1 = doc.cell({
    x: 2.15 * pdf.cm,
    y: 19.5 * pdf.cm,
    font: fontArial,
    fontSize: 11
  });
  cellGuiones1.text('-');
  cellGuiones1.text('-');
  cellGuiones1.text('-');
  const cellValues1 = doc.cell({
    x: 2.4 * pdf.cm,
    y: 19.5 * pdf.cm,
    font: fontArial,
    fontSize: 11,
    width: 8 * pdf.cm
  });
  cellValues1.text(`${university1.Value.ES[0]}`);
  cellValues1.text(`${university1.Value.ES[1]}`);
  cellValues1.text(`${university1.Value.ES[2]}`);

  // Universidad 2
  let logo2 = university2.Logo.replace(/\s/g, '-');
  await printLogo(doc, logo2, 11.2, 23);
  doc.cell({ x: 13.82 * pdf.cm, y: 23.05 * pdf.cm, width: 7 * pdf.cm }).text(`${university2.Name.toUpperCase()}`, {
    font: fontArialBold,
    fontSize: 11,
    color: '#41B9E7'
  });

  doc.cell(`${notaAcceso1}`, {
    x: 17.62 * pdf.cm,
    y: 20.92 * pdf.cm,
    font: fontArial,
    fontSize: 12
  });

  const cellGuiones2 = doc.cell({
    x: 12.22 * pdf.cm,
    y: 19.5 * pdf.cm,
    font: fontArial,
    fontSize: 11
  });
  cellGuiones2.text('-');
  cellGuiones2.text('-');
  cellGuiones2.text('-');
  const cellValues2 = doc.cell({
    x: 12.47 * pdf.cm,
    y: 19.5 * pdf.cm,
    font: fontArial,
    fontSize: 11,
    width: 8 * pdf.cm
  });
  cellValues2.text(`${university2.Value.ES[0]}`);
  cellValues2.text(`${university2.Value.ES[1]}`);
  cellValues2.text(`${university2.Value.ES[2]}`);

  // Potencial de mejora
  doc.cell(`${potencialMejora}`, {
    x: 7.99 * pdf.cm,
    y: 15.28 * pdf.cm,
    font: fontArial,
    fontSize: 12,
    textAlign: 'right',
    width: 42
  });
  doc.cell(`${pais === 'EE.UU' ? totalNota : notaMedia.toFixed(2)}`, {
    x: 7.99 * pdf.cm,
    y: 14.48 * pdf.cm,
    font: fontArial,
    fontSize: 12,
    textAlign: 'right',
    width: 42
  });

  // Universidad 3
  let logo3 = university3.Logo.replace(/\s/g, '-');
  await printLogo(doc, logo3, 1.5, 7.5);
  doc.cell({ x: 3.85 * pdf.cm, y: 7.6 * pdf.cm, width: 6.5 * pdf.cm }).text(`${university3.Name.toUpperCase()}`, {
    font: fontArialBold,
    fontSize: 11,
    color: '#41B9E7'
  });

  doc.cell(`${notaAcceso2}`, {
    x: 7.77 * pdf.cm,
    y: 5.36 * pdf.cm,
    font: fontArial,
    fontSize: 12
  });

  const cellGuiones3 = doc.cell({
    x: 2.15 * pdf.cm,
    y: 3.85 * pdf.cm,
    font: fontArial,
    fontSize: 11
  });
  cellGuiones3.text('-');
  cellGuiones3.text('-');
  cellGuiones3.text('-');
  const cellValues3 = doc.cell({
    x: 2.4 * pdf.cm,
    y: 3.85 * pdf.cm,
    font: fontArial,
    fontSize: 11,
    width: 8 * pdf.cm
  });
  cellValues3.text(`${university3.Value.ES[0]}`);
  cellValues3.text(`${university3.Value.ES[1]}`);
  cellValues3.text(`${university3.Value.ES[2]}`);

  // Universidad 4
  let logo4 = university4.Logo.replace(/\s/g, '-');
  await printLogo(doc, logo4, 11.2, 7.5);
  doc.cell({ x: 13.95 * pdf.cm, y: 7.6 * pdf.cm, width: 7 * pdf.cm }).text(`${university4.Name.toUpperCase()}`, {
    font: fontArialBold,
    fontSize: 11,
    color: '#41B9E7'
  });

  doc.cell(`${notaAcceso2}`, {
    x: 17.62 * pdf.cm,
    y: 5.36 * pdf.cm,
    font: fontArial,
    fontSize: 12
  });

  const cellGuiones4 = doc.cell({
    x: 12.22 * pdf.cm,
    y: 3.85 * pdf.cm,
    font: fontArial,
    fontSize: 11
  });
  cellGuiones4.text('-');
  cellGuiones4.text('-');
  cellGuiones4.text('-');
  const cellValues4 = doc.cell({
    x: 12.47 * pdf.cm,
    y: 3.85 * pdf.cm,
    font: fontArial,
    fontSize: 11,
    width: 8 * pdf.cm
  });
  cellValues4.text(`${university4.Value.ES[0]}`);
  cellValues4.text(`${university4.Value.ES[1]}`);
  cellValues4.text(`${university4.Value.ES[2]}`);
};

const getRama = (pais, tipoRama) => {
  let ramasEEUU = {};
  let ramasEuropa = {};

  if (pais === 'EE.UU') {
    ramasEEUU['Rama jurídica, abogado, juez...'] = 'Business';
    ramasEEUU['Rama empresa, economista, administración de empresas...'] = 'Business';
    ramasEEUU['Rama atención personas, psicología, trabajo social...'] = 'Humanities';
    ramasEEUU['Rama comunicación, periodismo, marketing...'] = 'Business';
    ramasEEUU['Artes escénicas, música...'] = 'Humanities';
    ramasEEUU['Artes plásticas, bellas artes...'] = 'Humanities';
    ramasEEUU['Artes digitales, diseño, animación...'] = 'Humanities';
    ramasEEUU['Filologías e idiomas...'] = 'Humanities';
    ramasEEUU['Historia...'] = 'Humanities';
    ramasEEUU['Salud, medicina, enfermería...'] = 'Biology';
    ramasEEUU['Ciencias aplicadas, biología...'] = 'Biology';
    ramasEEUU['Ciencias específicas, física, química, matemáticas...'] = 'Engineering';
    ramasEEUU['Ingeniería y arquitectura'] = 'Engineering';
    ramasEEUU['Deporte'] = 'Sports Related';
    return ramasEEUU[tipoRama];
  } else {
    ramasEuropa['Rama jurídica, abogado, juez...'] = 'Juridical';
    ramasEuropa['Rama empresa, economista, administración de empresas...'] = 'Business';
    ramasEuropa['Rama atención personas, psicología, trabajo social...'] = 'Humanities';
    ramasEuropa['Rama comunicación, periodismo, marketing...'] = 'Communication';
    ramasEuropa['Artes escénicas, música...'] = 'Arts';
    ramasEuropa['Artes plásticas, bellas artes...'] = 'Arts';
    ramasEuropa['Artes digitales, diseño, animación...'] = 'Design';
    ramasEuropa['Filologías e idiomas...'] = 'Humanities';
    ramasEuropa['Historia...'] = 'Humanities';
    ramasEuropa['Salud, medicina, enfermería...'] = 'Biology';
    ramasEuropa['Ciencias aplicadas, biología...'] = 'Biology';
    ramasEuropa['Ciencias específicas, física, química, matemáticas...'] = 'Mathematics';
    ramasEuropa['Ingeniería y arquitectura'] = 'Engineering';
    ramasEuropa['Deporte'] = 'Sport';
    return ramasEuropa[tipoRama];
  }
};

const printLogo = async (doc, logo, positionX, positionY) => {
  const res = await fetch(`/images/logosUniversidades/${logo}`);
  const buffer = await res.arrayBuffer();
  const image = new pdf.Image(buffer);
  doc.cell({ x: positionX * pdf.cm, y: positionY * pdf.cm }).image(image, { width: 2 * pdf.cm, height: 2 * pdf.cm });
};

const getUniversidadDeportiva = (inscripcionAlumno, rama, totalNota, categoria) => {
  totalNota = totalNota < 500 ? 500 : totalNota;
  const rango = Object.entries(satScoreAmerica['Deportiva']?.[categoria]).find(element => {
    let rangoSatScore = element[0].split('-');
    return totalNota >= parseInt(rangoSatScore[0]) && totalNota < parseInt(rangoSatScore[1]);
  });
  let universities = {
    universityName1: {},
    universityName2: {},
    rango: ''
  };
  let listaUniversidades = JSON.parse(JSON.stringify(rango[1][rama]));
  console.log('listaUniversidades', listaUniversidades);

  universities.rango = rango[0].split('-')[0];
  let random1 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName1 = listaUniversidades[random1];
  listaUniversidades.splice(listaUniversidades.indexOf(universities.universityName1), 1);
  let random2 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName2 = listaUniversidades[random2];
  console.log('listaUniversidades', listaUniversidades);

  return universities;
};

const getUniversidadAcademicaEEUU = (rama, totalNota) => {
  totalNota = totalNota === 400 ? 500 : totalNota > 1649 ? 1649 : totalNota;
  //console.log("satScoreAmerica[Académica]", satScoreAmerica["Académica"])
  //console.log(totalNota, totalNota)

  const rango = Object.entries(satScoreAmerica['Académica']).find(element => {
    let rangoSatScore = element[0].split('-');
    return totalNota >= parseInt(rangoSatScore[0]) && totalNota < parseInt(rangoSatScore[1]);
  });
  //console.log("rango", rango)

  let universities = {
    universityName1: {},
    universityName2: {},
    rango: ''
  };

  let listaUniversidades = JSON.parse(JSON.stringify(rango[1][rama]));
  universities.rango = rango[0].split('-')[0];
  let random1 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName1 = listaUniversidades[random1];
  listaUniversidades.splice(listaUniversidades.indexOf(universities.universityName1), 1);
  let random2 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName2 = listaUniversidades[random2];
  return universities;
};

const getUniversidadAcademicaEuropa = (pais, notaMedia, rama) => {
  console.log('@@', pais, notaMedia, rama);

  const rango = Object.entries(satScoreEuropa[pais]).find(element => {
    let rangoNotas = element[0].split('-');
    return +notaMedia >= parseInt(rangoNotas[0]) && +notaMedia < parseFloat(rangoNotas[1]);
  });
  console.log('@@@', rango);

  let universities = {
    universityName1: {},
    universityName2: {},
    rango: ''
  };

  let listaUniversidades = JSON.parse(JSON.stringify(rango[1][rama]));
  console.log('listaUniversidades', listaUniversidades);
  universities.rango = rango[0].split('-')[0];
  let random1 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName1 = listaUniversidades[random1];
  listaUniversidades.splice(listaUniversidades.indexOf(universities.universityName1), 1);
  let random2 = Math.floor(Math.random() * (listaUniversidades.length - 0) + 0);
  universities.universityName2 = listaUniversidades[random2];
  console.log('universities', universities);

  return universities;
};

const getNotaPotencial = (cursoActual, totalNota, cursoAnterior, matematicasNota, inglesNota) => {
  let edad = cursoActual === '4º ESO' ? 200 : 100;
  let resultado = totalNota < 1000 ? 100 : totalNota < 1200 ? 50 : 0;
  let matematicas, ingles;

  if (cursoAnterior.mediaMatematicas > 9) {
    if (matematicasNota < 600) {
      let aux = 600 - matematicasNota;
      matematicas = aux <= 0 ? 0 : aux;
    } else {
      matematicas = 0;
    }
  } else {
    let aux = 500 - matematicasNota;
    matematicas = aux <= 0 ? 0 : aux;
  }

  if (cursoAnterior.mediaIngles > 9) {
    if (inglesNota < 500) {
      let aux = 500 - inglesNota;
      ingles = aux <= 0 ? 0 : aux;
    } else {
      ingles = 0;
    }
  } else {
    let aux = 400 - inglesNota;
    ingles = aux <= 0 ? 0 : aux;
  }

  let potencialMejora = edad + resultado + matematicas + ingles;
  return potencialMejora;
};

const printGuias = async (doc, numPagGuia1, numPagGuia2) => {
  let guia1 = await getPage('Guia1');
  let guia2 = await getPage('Guia2');

  doc.image(guia1, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });
  doc.cell(numPagGuia1, {
    font: fontArialBold,
    fontSize: 9,
    x: 18.6 * pdf.cm,
    y: 28.3 * pdf.cm
  });

  doc.pageBreak();

  doc.image(guia2, {
    align: 'center',
    width: 21 * pdf.cm,
    height: 29.7 * pdf.cm
  });
  doc.cell(numPagGuia2, {
    font: fontArialBold,
    fontSize: 9,
    x: 18.6 * pdf.cm,
    y: 28.3 * pdf.cm
  });
};

const paginaEuropea = {
  'Rama jurídica, abogado, juez...': 'Europea-Derecho',
  'Rama empresa, economista, administración de empresas...': 'Europea-Empresa',
  'Rama atención personas, psicología, trabajo social...': 'Europea-Medicina',
  'Rama comunicación, periodismo, marketing...': 'Europea-Marketing',
  'Artes escénicas, música...': 'Europea-Artes',
  'Artes plásticas, bellas artes...': 'Europea-Artes',
  'Artes digitales, diseño, animación...': 'Europea-Artes',
  'Filologías e idiomas...': 'Europea-Social',
  'Historia...': 'Europea-Social',
  'Salud, medicina, enfermería...': 'Europea-Medicina',
  'Ciencias aplicadas, biología...': 'Europea-Ciencias',
  'Ciencias específicas, física, química, matemáticas...': 'Europea-Ciencias',
  'Ingeniería y arquitectura': 'Europea-Arquitectura',
  Deporte: 'Europea-Deporte'
};

export const sendEmail = async email => {
  return await axios
    .get(`/api/global/sendEmailAlumn?email=${email}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(err => {
      return Promise.resolve(false);
    });
};

export const sendEmailSchool = async email => {
  return await axios
    .get(`/api/global/sendEmailSchool?email=${email}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(err => {
      return Promise.resolve(false);
    });
};
