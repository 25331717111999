import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import logo from '../../images/logos/w2aLogo.png'
import styled from 'styled-components'
class HeaderW2A extends Component {

  constructor(props){
    super(props);
    this.state = {
      svg:'<svg version="1.1" className="trisvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" preserveAspectRatio="none" width="14px" height="5px" viewBox="0 0 14.017 5.006" enable-background="new 0 0 14.017 5.006" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.016,0L7.008,5.006L0,0H14.016z"></path></svg>',
      fecha:null
    }
  }

  componentDidMount = () =>{
    var d = new Date();
    var months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    var mes = months[d.getMonth()];
    var dia = d.getDate();
    var year = d.getFullYear();

    var fecha = `${mes} ${dia} ${year}`
    this.setState({fecha: fecha.toLowerCase()})
  }

  render() {

    return (

      <div className='header-w2a'>
        <header id="header" className="site-header  style7 cta_button  header--follow    sticky-resize headerstyle--default site-header--absolute nav-th--light sheader-sh--light" role="banner" itemScope="itemScope" itemType="https://schema.org/WPHeader">
          <div className="kl-header-bg "></div>
          <div className="site-header-wrapper sticky-top-area">
            <div className="site-header-top-wrapper topbar-style--default  sh--light">
              <div className="siteheader-container container">
                <div className="fxb-row site-header-row site-header-top ">
                  <div className="fxb-col fxb fxb-start-x fxb-center-y fxb-basis-auto site-header-col-left site-header-top-left">
                    <ul className="sh-component social-icons sc--clean topnav navRight topnav-no-hdnav">
                      <li className="topnav-li social-icons-li">
                        <a rel="noopener noreferrer" href="https://es-la.facebook.com/unidreamoficial" data-zniconfam="kl-social-icons" data-zn_icon="" target="_self" className="topnav-item social-icons-item scheader-icon-" title="Facebook" > </a>
                      </li>
                      <li className="topnav-li social-icons-li">
                        <a rel="noopener noreferrer" href="https://www.linkedin.com/company/unidream" data-zniconfam="kl-social-icons" data-zn_icon="" target="_self" className="topnav-item social-icons-item scheader-icon-" title="Linked’in"> </a>
                      </li>
                      <li className="topnav-li social-icons-li">
                        <a rel="noopener noreferrer" href="https://www.instagram.com/unidream_oficial" data-zniconfam="kl-social-icons" data-zn_icon="" target="_blank" className="topnav-item social-icons-item scheader-icon-" title="Instagram"> </a>
                      </li>
                    </ul>
                    <div className="sh-component kl-header-toptext kl-font-alt">LLÁMANOS!
                      <a href="# " className="fw-bold"> +34 621 30 86 50</a>
                    </div>
                  </div>
                  <div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto site-header-col-right site-header-top-right"></div>
                </div>
                <div className="separator site-header-separator "></div>
              </div>
            </div>

            <div className="kl-top-header site-header-main-wrapper clearfix   header-no-bottom  sh--light">
              <div className="container siteheader-container ">
                <div className="fxb-col fxb-basis-auto">
        <div className="fxb-row site-header-row site-header-main ">

          <div className="fxb-col fxb fxb-start-x fxb-center-y fxb-basis-auto fxb-grow-0 fxb-sm-full site-header-col-left site-header-main-left">
            <div id="logo-container" className="logo-container hasInfoCard  logosize--yes zn-original-logo">
              <LogoContainer>
                <h3 className="site-logo logo " id="logo"><a href="https://unidream.es/" className="site-logo-anch"><img className="logo-img site-logo-img" style={{width: "190px"}} src={logo} alt="Unidream" title="Universidad en Estados Unidos"/></a></h3>
              </LogoContainer>
                
              </div>

                    <div className="separator site-header-separator visible-xs"></div>	</div>

                    <div className="fxb-col fxb fxb-center-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-col-center site-header-main-center">
                        <div className="sh-component main-menu-wrapper" role="navigation" itemScope="itemScope" itemType="https://schema.org/SiteNavigationElement">
                        <div className="zn-res-menuwrapper">
                        <a href="# " className="zn-res-trigger zn-menuBurger zn-menuBurger--3--s zn-menuBurger--anim1" id="zn-res-trigger">
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>
                      </div>
                      <div id="main-menu" className="main-nav mainnav--overlay mainnav--active-bg mainnav--pointer-dash nav-mm--light zn_mega_wrapper ">
                        <ul id="menu-menu-principal-espanol" className="main-menu main-menu-nav zn_mega_menu ">
                          <li id="menu-item-16239" className={`${this.props.categoria==='Inscripción de colegios'?'active':''} main-menu-item menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16211 current_page_item menu-item-16239 main-menu-item-top  menu-item-even menu-item-depth-0`}><a href="https://inscripciones.unidream.es/formulario-colegios/" className=" main-menu-link main-menu-link-top"><span>Inscripción de Colegios</span></a></li>
                          <li id="menu-item-16239" className={`${this.props.categoria==='Programa Unidream 2022'?'active':''} main-menu-item menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-16211 current_page_item menu-item-16239 main-menu-item-top  menu-item-even menu-item-depth-0`}><a href="https://inscripciones.unidream.es/formulario-alumnos/" className=" main-menu-link main-menu-link-top"><span>Inscripción de Alumnos</span></a></li>
                          <li id="menu-item-16249" className={`main-menu-item menu-item menu-item-type-post_type menu-item-object-page menu-item-16249  main-menu-item-top  menu-item-even menu-item-depth-0`}><a href="https://unidream.es/" className=" main-menu-link main-menu-link-top"><span>Ediciones Anteriores</span></a></li>
                          <li style={{visibility: 'hidden'}} id="menu-item-16292" className={`${this.props.categoria==='Formulario Alumnos'?'active':''} main-menu-item menu-item menu-item-type-custom menu-item-object-custom menu-item-16292  main-menu-item-top  menu-item-even menu-item-depth-0`}><a href="https://inscripciones.unidream.es/formulario-alumnos" className=" main-menu-link main-menu-link-top"><span>Inscripción de Alumnos</span></a></li>
                          <li style={{visibility: 'hidden'}} id="menu-item-15505" className={`main-menu-item menu-item menu-item-type-post_type menu-item-object-page menu-item-15505  main-menu-item-top  menu-item-even menu-item-depth-0`}><a href="https://unidream.es/nuestra-empresa/" className=" main-menu-link main-menu-link-top"><span>Empresa</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                    <div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-col-right site-header-main-right">
                      <div className="fxb-col fxb fxb-end-x fxb-center-y fxb-basis-auto fxb-sm-half site-header-main-right-top">
                        <a href="https://unidream.es/contact/" id="ctabutton" className="sh-component ctabutton kl-cta-ribbon " title="CONTACTO" target="_self" itemProp="url">
                          <strong>CONTACTO</strong>
                          {renderHTML(this.state.svg)}
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="page_header" className="page-subheader page-subheader--auto page-subheader--inherit-hp uh_zn_def_header_style  psubhead-stheader--absolute sh-tcolor--light" >
          <div className="bgback"></div>
          <div className="th-sparkles"></div>
          <div className="ph-content-wrap">
            <div className="ph-content-v-center">
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul vocab="http://schema.org/" typeof="BreadcrumbList" className="breadcrumbs fixclear bread-style--black">
                        <li property="itemListElement" typeof="ListItem">
                          <a property="item" typeof="WebPage" href="https://unidream.es">Inicio</a>
                        </li>
                        <li>{this.props.categoria}</li>
                      </ul>
                      <span id="current-date" className="subheader-currentdate hidden-xs">{this.state.fecha}</span>
                      <div className="clearfix"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="subheader-titles">
                        <h2 className="subheader-maintitle" itemProp="headline">{this.props.categoria}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="zn_header_bottom_style"></div>
        </div>
      </div>
    )
  }
}

export default HeaderW2A;

const LogoContainer = styled.div`
  background: #ffffff4f;
  padding: 0px 12px;
  border-radius: 3px;
`