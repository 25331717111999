import React, { useState, useEffect } from 'react';
import { validarDni, onFocusInscripciones, onBlurInscripciones, validarTelefono, validFechaNacimiento } from '../../../../../../utils/functions';
import { opcionesSexo } from '../../../../../../utils/constans';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { modifyCurrentAlumno } from '../../../../../../redux/actions/alumnosActions';
import ListaOpciones from '../../../Shared/ListaOpciones';
const DatosAlumno = props => {
  const { errors } = props;
  /*
  const [edited, setEdited] = useState(false)
  const [state, setState] = useState({...props})
  useEffect(()=> { setEdited(!_.isEqual(state, props)) }, [state])
  useEffect(()=> { setState({...props}) }, [props])
  */

  const [datosValidados, setDatosValidados] = useState(true);
  const [showOptionsSexo, setShowOptionsSexo] = useState(false);

  return (
    <div>
      <h3 className='title_form'>
        <span className='fw_thin'>Datos del</span> alumno
      </h3>

      <div className='pr width_100 colums_form_2'>
        {/*DNI DEL Alumno*/}
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form dni-input ${errors.dni ? 'obligatorio' : ''}`}
            value={props.dni}
            onChange={e => props.actions.modifyCurrentAlumno({ value: validarDni(e.target.value), path: 'informacionAlumno.dni' })}
          />
          <label className='label_form dni-input'>DNI</label>
        </div>

        {/*EAMIL*/}
        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form ${errors.email ? 'obligatorio' : ''}`}
            value={props.email}
            onChange={e =>
              !props.isAuthenticated ? props.actions.modifyCurrentAlumno({ value: e.target.value.toLowerCase(), path: 'informacionAlumno.email' }) : null
            }
          />
          <label className='label_form'>Email</label>
        </div>
      </div>

      {!props.isAuthenticated && (
        <div className='pr width_100 colums_form_2'>
          {/*PASSWORD*/}
          <div className='pr width_100 colums_form_2_margin_right'>
            <input
              type='password'
              onFocus={e => onFocusInscripciones(e)}
              onBlur={e => onBlurInscripciones(e)}
              className={`input_form spacing_number ${errors.password ? 'obligatorio' : ''}`}
              value={props.password}
              onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.password' })}
            />
            <label className='label_form'>Contraseña</label>
            <div className='text-explicativo'>Crea una contraseña para poder volver a entrar a la inscripción más adelante. Mínimo 6 caracteres</div>
          </div>
          {/*REPETIR PASSWORD*/}
          <div className='pr width_100 colums_form_2_margin_left'>
            <input
              type='password'
              onFocus={e => onFocusInscripciones(e)}
              onBlur={e => onBlurInscripciones(e)}
              className={`input_form spacing_number ${errors.repetirPassword ? 'obligatorio' : ''}`}
              value={props.repetirPassword}
              onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.repetirPassword' })}
            />
            <label className='label_form'>Repetir contraseña</label>
          </div>
        </div>
      )}
      <br />
      <br />

      <div className='pr width_100 colums_form_2'>
        {/*NOMBRE DEL Alumno*/}
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form ${errors.nombre ? 'obligatorio' : ''}`}
            value={props.nombre}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.nombre' })}
          />
          <label className='label_form'>Nombre</label>
        </div>

        {/*APELLIDO DEL Alumno*/}
        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form ${errors.apellidos ? 'obligatorio' : ''}`}
            value={props.apellidos}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.apellidos' })}
          />
          <label className='label_form'>Apellidos</label>
        </div>
      </div>

      <div className='pr width_100 colums_form_2'>
        {/*TELEFONO*/}
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form ${errors.telefono ? 'obligatorio' : ''}`}
            value={props.telefono}
            onChange={e => props.actions.modifyCurrentAlumno({ value: validarTelefono(e.target.value), path: 'informacionAlumno.telefono' })}
          />
          <label className='label_form'>Teléfono</label>
        </div>

        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => onFocusInscripciones(e)}
            onBlur={e => onBlurInscripciones(e)}
            className={`input_form spacing_number ${errors.fechaNacimiento ? 'obligatorio' : ''}`}
            value={props.fechaNacimiento}
            onChange={e =>
              props.actions.modifyCurrentAlumno({
                value: validFechaNacimiento(e.target.value, props.fechaNacimiento),
                path: 'informacionAlumno.fechaNacimiento'
              })
            }
          />
          <label className='label_form'>Fecha de nacimiento</label>
        </div>
      </div>

      <div className='pr width_100 colums_form_2'>
        {/*Sexo*/}
        <div className={`pr pointer select_form colums_form_2_margin_right ${errors.sexo ? 'obligatorio' : ''}`} onClick={() => setShowOptionsSexo(true)}>
          <div className='item_selected'>{props.sexo}</div>
          <label className='label_form pointer'>Sexo</label>
          {showOptionsSexo && (
            <ListaOpciones
              editOpciones={value => setShowOptionsSexo(value)}
              opciones={opcionesSexo}
              opcionSelectedUpdate={value => props.actions.modifyCurrentAlumno({ value, path: 'informacionAlumno.sexo' })}
              opcionSelected={props.sexo}
            />
          )}
        </div>
        <div className='pr width_100 colums_form_2_margin_left opacity_0'>
          <input className={`input_form disapear-700`} style={{ cursor: 'default' }} value={''} readOnly={true} />
        </div>
      </div>

      <br />
      <br />

      <div className='pr'>
        <input
          onFocus={e => onFocusInscripciones(e)}
          onBlur={e => onBlurInscripciones(e)}
          className={`input_form ${errors.direccion ? 'obligatorio' : ''}`}
          value={props.direccion}
          onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.direccion' })}
        />
        <label className='label_form'>Dirección</label>
      </div>

      <div className='pr width_100 colums_form_2'>
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form ${errors.piso ? 'obligatorio' : ''}`}
            value={props.piso}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.piso' })}
          />
          <label className='label_form'>Piso</label>
        </div>
        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form ${errors.numero ? 'obligatorio' : ''}`}
            value={props.numero}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.numero' })}
          />
          <label className='label_form'>Número</label>
        </div>
      </div>

      <div className='pr width_100 colums_form_2'>
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form`}
            value={props.letra}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.letra' })}
          />
          <label className='label_form'>Letra</label>
        </div>
        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form ${errors.codigoPostal ? 'obligatorio' : ''}`}
            value={props.codigoPostal}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.codigoPostal' })}
          />
          <label className='label_form'>Código postal</label>
        </div>
      </div>

      <div className='pr width_100 colums_form_2'>
        <div className='pr width_100 colums_form_2_margin_right'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form ${errors.ciudad ? 'obligatorio' : ''}`}
            value={props.ciudad}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.ciudad' })}
          />
          <label className='label_form'>Ciudad</label>
        </div>
        <div className='pr width_100 colums_form_2_margin_left'>
          <input
            onFocus={e => {
              onFocusInscripciones(e);
            }}
            onBlur={e => {
              onBlurInscripciones(e);
            }}
            className={`input_form ${errors.provincia ? 'obligatorio' : ''}`}
            value={props.provincia}
            onChange={e => props.actions.modifyCurrentAlumno({ value: e.target.value, path: 'informacionAlumno.provincia' })}
          />
          <label className='label_form'>Provincia</label>
        </div>
      </div>
    </div>
  );
};

const matchDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        modifyCurrentAlumno
      },
      dispatch
    )
  };
};

export default connect(null, matchDispatchToProps)(DatosAlumno);
