import Responsable from './Responsable'
import Alumno from './Alumno'
import { isEmail, isTelefono } from '../utils/functions'

class Colegio {
  id = null
  direccion = ""
  fundacion = ""
  nombre = ""
  participacionAnterior = false
  provincia = ""
  terminos = false
  responsables = [
    {
      nombre: "",
      apellidos: "",
      email: "",
      telefonoMovil: "",
      telefonoFijo: "",
      cargo: "",
      horarioPreferente: ""
    },
    {
      nombre: "",
      apellidos: "",
      email: "",
      telefonoMovil: "",
      telefonoFijo: "",
      cargo: "",
      horarioPreferente: ""
    }
  ]
  timestamp = 0
  alumnos = {}
  examen = {
    habilitado: false,
    fechaExamen: '',
    horaExamen: '',
    alumnosHabilitados: {}
  }
  inscripciones = {}

  constructor(obj){
    if(obj){
      this.id = obj.id ?? this.id
      this.direccion = obj.direccion ?? this.direccion
      this.fundacion = obj.fundacion ?? this.fundacion
      this.nombre = obj.nombre ?? this.nombre
      this.participacionAnterior = obj.participacionAnterior ?? this.participacionAnterior
      this.provincia = obj.provincia ?? this.provincia
      this.responsables = obj.responsables ? obj.responsables.map(responsable => new Responsable(responsable)) : this.responsables
      this.timestamp = obj.timestamp ?? this.timestamp
      this.alumnos = obj.alumnos ?? this.alumnos
      this.inscripciones = obj.inscripciones ?? this.inscripciones
      this.examen = {
        habilitado: obj.examen?.habilitado ?? this.examen.habilitado ,
        fechaExamen: obj.examen?.fechaExamen ?? this.examen.fechaExamen ,
        horaExamen: obj.examen?.horaExamen ?? this.examen.horaExamen ,
        alumnosHabilitados: obj.examen?.alumnosHabilitados ?? this.examen.alumnosHabilitados ,
      }
      this.terminos = obj.terminos ?? this.terminos
    }
  }

  checkColegio = () => {
    let errors = {}
    if(!this.nombre.trim()){errors.nombre=true}
    if(!this.direccion.trim()){errors.direccion=true}
    if(!this.provincia.trim()){errors.provincia=true}

    if(!this.responsables[0].nombre.trim()){errors.nombreR0=true}
    if(!this.responsables[0].apellidos.trim()){errors.apellidosR0=true}
    if(!isEmail(this.responsables[0].email.trim())){errors.emailR0=true}
    if(!isTelefono(this.responsables[0].telefonoMovil.trim())){errors.telefonoMovilR0=true}
    if(!isTelefono(this.responsables[0].telefonoFijo.trim())){errors.telefonoFijoR0=true}
    if(!this.responsables[0].cargo.trim()){errors.cargoR0=true}
    if(!this.responsables[0].horarioPreferente.trim()){errors.horarioPreferenteR0=true}

    if(this.responsables[1].email.trim() && !isEmail(this.responsables[1].email.trim())){errors.emailR1=true}
    if(this.responsables[1].telefonoMovil.trim() && !isTelefono(this.responsables[1].telefonoMovil.trim())){errors.telefonoMovilR1=true}
    if(this.responsables[1].telefonoFijo.trim() && !isTelefono(this.responsables[1].telefonoFijo.trim())){errors.telefonoFijoR1=true}
    if(!this.terminos){errors.terminos = true}
    return errors
  }
}

export default Colegio