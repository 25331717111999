import React, { useLayoutEffect, useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { getAlumno, getAlumnoInscripcion } from '../../redux/actions/alumnosActions'
import { getColegio } from '../../redux/actions/colegiosActions'
import { Link } from 'react-router-dom';
import { DescriptionRounded, AssignmentIndRounded, ExitToAppRounded, GetAppRounded, AssignmentRounded } from '@material-ui/icons'
import HeaderW2A from '../W2AViews/HeaderW2A'
import FooterW2A from '../W2AViews/FooterW2A'
import { pathFormularioModifyAlumno, pathSigninAlumno } from '../../utils/constans'
import moment from 'moment'
import { signOut } from '../../utils/firebase';
import { db } from '../../utils/firebase'
import { generatePDF } from '../../utils/functions'
import LoadingPanel from '../Shared/LoadingPanel'
import styled from 'styled-components'
const AreaAlumno = ({currentAlumno, currentColegio, inscripcion, actions}) => {

  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)
  const [loadingPanel, setLoadingPanel] = useState(false)
  const [popupInfo, setPopupInfo] = useState(false)
  

  useLayoutEffect(()=>{
    getData()
  },[])

  const getData = async () => {
    await actions.getAlumno()
    const inscripcionAlumno = await actions.getAlumnoInscripcion()
    //if(inscripcionAlumno.datosColegio.colegio.id!=="nuevo"){
      await actions.getColegio({idColegio: inscripcionAlumno.datosColegio.colegio.id})
    //}
    setLoading(false)
  }

  const logout = () => {
    signOut()
    window.location.href = pathSigninAlumno
  }

  const checkExamen = (e) => {
    if(!currentColegio.examen.habilitado || !currentColegio.examen.alumnosHabilitados[currentAlumno.id]){
      e.preventDefault()
    }
  }

  const uploadTemplate = () => {
    const blob = new Blob([file],{type: "application/pdf"})

    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
        var base64data = reader.result;                
        let multipath = {}
        multipath[`Settings/informeAlumno/template`] = base64data
        db.update(multipath)
        .then(() => console.log('OK') )
        .catch((err) => console.log(err))
    }
  }

  const openInformeAD20 = async () => {
    setLoadingPanel(true)
    const fileURL = await generatePDF(currentAlumno, inscripcion) ?? 404
    setLoadingPanel(false)
    if(fileURL === 404){
      setPopupInfo(true)
    }else{
      window.open(fileURL);
    }
  }

  const isSatDone = currentAlumno.inscripcionAlumno.isSatDone()

  return(
    loading ? <div>Loading...</div> :
    <div className='bg-w2a'>
      <HeaderW2A categoria='Área Alumno' />

      <div className="container-area-alumo">

        <div className="content-area-alumno"> 

          <div className="title-area">
            {`Bienvenido ${currentAlumno.informacionAlumno.nombre}`}
            <div className="logout-btn" onClick={logout}>
              <ExitToAppRounded />
              <span>Logout</span>
            </div>
            {/* <input type='file' onChange={e => setFile(e.target.files[0])} /> */}
          </div>
          
          <div className="panels-area">

          <div className="panel-area data-user-card ">
            <Link to={pathFormularioModifyAlumno}>
              <div className="card-area">
                <div className="card-icon">
                  <div className="container-icon"><AssignmentIndRounded /></div>

                </div>

                <div className="card-content">
                    <div className="title-card">Datos del alumno</div>
                    <div className="description-card">Puedes ver o editar los datos de tu inscripción</div>
                </div>
              </div>
            </Link>
            </div>
            <div className="panel-area examen-sat-card">
              <Link to={`/examen/${currentColegio.id}`} onClick={(e)=>checkExamen(e)}>
                <div className="card-area">
                  <div className="card-icon">
                    <div className="container-icon"><DescriptionRounded /></div>
                  </div>
                  <div className="card-content">
                      <div className="title-card">Examen SAT</div>
                        {/*isSatDone && 
                          <div className="description-card">Ya has realizado el examen</div>
                        */}

                        {/*!isSatDone && */ !currentColegio.examen.habilitado && 
                          <div className="description-card">No se ha habilitado ningún examen para tu colegio</div>
                        }
                        {/*!isSatDone && */ currentColegio.examen.habilitado && !currentColegio.examen.alumnosHabilitados[currentAlumno.id] && 
                          <div className="description-card">No estás convocado a ningún examen</div>
                        }
                        {/*!isSatDone && */ currentColegio.examen.habilitado && currentColegio.examen.alumnosHabilitados[currentAlumno.id] && 
                          <div className="description-card">Tienes un examen el día {moment(currentColegio.examen.fechaExamen).format('DD/MM/YYYY')} a las {currentColegio.examen.horaExamen}</div>
                        }
                      
                  </div>
                </div>
              </Link>
            </div>
            <div className="panel-area informe-sat-card" onClick={openInformeAD20}>
              <div className="card-area">
                <div className="card-icon">
                  <div className="container-icon"><AssignmentRounded /></div>
                </div>
                <div className="card-content">
                    <div className="title-card">Informe Programa Unidream 2022</div>
                    <div className="description-card">Descarga tu informe pulsando aquí</div>
                </div>
              </div>
            </div>

            {loadingPanel && <LoadingPanel />}

            { popupInfo && 
              <PopupInfo>
                <CardPopupInfo>
                  <div className="btn_cerrar_pop_up pointer" onClick={() => setPopupInfo(false)}><i className="material-icons">close</i></div>
                  Has completado la inscripción. Para recibir el informe de recomendación, tienes que completar la prueba de nivel. Ponte en contacto con tu colegio o escríbenos a hola@unidream.es para más información.  
                </CardPopupInfo>
              </PopupInfo>
            }
            
          </div>
        </div>


      </div>
      
      <FooterW2A />
    </div>
  )
}

const mapStateToProps = ({ alumnos, colegios, global }) => {
  return {
    currentAlumno: alumnos.currentAlumno,
    currentColegio: colegios.currentColegio,
    inscripcion: global.inscripcion
  };
}

const matchDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({
      getAlumno,
      getAlumnoInscripcion,
      getColegio
    }, dispatch)	
	};
}; 

export default connect(mapStateToProps, matchDispatchToProps)(AreaAlumno);


const PopupInfo = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.57);
  width: 100vw;
  height: 100vh;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CardPopupInfo = styled.div`
  background: #fff;
  max-width: 500px;
  padding: 24px;
  position: relative;
  border-radius: 6px;
  font-size: 16px;
  -webkit-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); 
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

`